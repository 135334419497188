import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBooking } from '../../BookingContext/DataContext'
import { API_BASE_URL } from '../../../Config/Config'
import 'rsuite/dist/rsuite.min.css'
import './Quote.css'

export default function Quote() {
  const [showForm, setShowForm] = useState(false)

  const { bookingData, setBookingData } = useBooking()
  const navigate = useNavigate()
  const [airports, setAirports] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Airport/GetAllAirportDetails`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        console.log(data)

        setAirports(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchAirports()
  }, [])

  // Default parking start and end dates
  useEffect(() => {
    if (!bookingData.parkingStart || !bookingData.parkingEnd) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      const dayAfterTomorrow = new Date(today)
      dayAfterTomorrow.setDate(today.getDate() + 2)

      setBookingData((prevData) => ({
        ...prevData,
        parkingStart: tomorrow.toISOString().slice(0, 16),
        parkingEnd: dayAfterTomorrow.toISOString().slice(0, 16),
        totalCostAll: bookingData.totalCostAll || 0,
      }))
    }
  }, [bookingData, setBookingData])

  const validateForm = () => {
    const newErrors = {}
    const { parkingStart, parkingEnd, airportId } = bookingData

    if (!airportId) {
      newErrors.airportName = 'Please select a location.'
    }

    if (!parkingStart) {
      newErrors.parkingStart = 'Please select a parking start time.'
    }

    if (!parkingEnd) {
      newErrors.parkingEnd = 'Please select a parking end time.'
    }

    const startDate = new Date(parkingStart)
    const endDate = new Date(parkingEnd)

    if (startDate < new Date()) {
      newErrors.parkingStart = 'Parking start time cannot be in the past.'
    } else {
    }

    if (startDate.getHours() < 4 || endDate.getHours() < 4) {
      newErrors.timeRestriction =
        'Parking times cannot be between 12 AM and 4 AM.'
    }
    if (endDate <= startDate) {
      newErrors.parkingEnd = 'Parking end time must be later than start time.'
    } else if (endDate - startDate < 3600000) {
      newErrors.parkingEnd =
        'Parking end time must be at least 1 hour after start time.'
    }

    setErrors(newErrors)

    return Object.keys(newErrors)?.length === 0
  }
  console.log(bookingData.airportName)

  const handleOnAirportChange = (e) => {
    const selectedId = e.target.value
    const selectedAirport = airports.find(
      (airport) => airport.airportID === parseInt(selectedId, 10)
    )

    setBookingData((prevData) => ({
      ...prevData,
      airportId: selectedAirport?.airportID || '',
      airportName: selectedAirport?.airportName || '',
      airportCode: selectedAirport?.airportZipCode || '',
      totalCostAll: prevData.totalCostAll || 0,

      outBoundTerminalName: '',
      outBoundTerminalId: '',
      outBoundTerminalCost: 0,

      inBoundTerminalName: '',
      inBoundTerminalId: '',
      inBoundTerminalCost: 0,

      carCleanName: 'None',
      carCleanId: null,
      carCleanCost: 0,

      electricChargeName: 'None',
      electricChargeId: null,
      electricChargeCost: 0,
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      const startDate = new Date(bookingData.parkingStart)
      const endDate = new Date(bookingData.parkingEnd)
      const differenceInDays = Math.ceil(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      )

      try {
        const response = await fetch(`${API_BASE_URL}/api/Booking/Quote`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            airportId: bookingData.airportId,
            startDateTime: bookingData.parkingStart,
            endDateTime: bookingData.parkingEnd,
            discountCode: bookingData.discountCode,
          }),
        })

        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        const data = await response.json()
        // console.log(data)
        const updatedTotalDaysCost = data.price.totalRate
        setBookingData((prevData) => ({
          ...prevData,
          quoteCode: data.quote.quoteCode,
          totalDays: differenceInDays,
          totalDaysCost: updatedTotalDaysCost,
          totalCostAll:
            updatedTotalDaysCost +
            (prevData.outBoundTerminalCost || 0) +
            (prevData.inBoundTerminalCost || 0) +
            (prevData.carCleanCost || 0) +
            (prevData.electricChargeCost || 0),
        }))

        navigate('/product_option')
      } catch (err) {
        console.error(err)
      }
    }
  }
  useEffect(() => {
    setShowForm(true)
    const handleScroll = () => {
      if (window.scrollY > 590) {
        // Adjust this value to control when the button appears
        setShowForm(false)
      } else {
        setShowForm(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {showForm && (
        <div className="col-lg-3 col-md-3 col-sm-3 form-shade">
          <form className="booking-form">
            <div data-mdb-input-init className="form-outline mb-3">
              <label htmlFor="Location">Location</label>
              <select
                className="rounded px-2 form-control"
                id="airportSelect"
                onChange={handleOnAirportChange}
                value={bookingData.airportId || ''}
              >
                <option value="" disabled>
                  Select an airport
                </option>
                {airports?.map((airport) => (
                  <option key={airport.airportID} value={airport.airportID}>
                    {airport.airportName}
                  </option>
                ))}
              </select>
              {errors.airportName && (
                <small className="error-home">{errors.airportName}</small>
              )}
            </div>
            <div data-mdb-input-init className="form-outline mb-3">
              <label className="form-label" htmlFor="form4Example2">
                Parking from
              </label>
              <input
                name="parkingStart"
                type="datetime-local"
                className="form-control"
                value={bookingData.parkingStart}
                onChange={handleChange}
                min={new Date().toISOString().slice(0, 16)}
              />
              {errors.parkingStart && (
                <small className="error-home">{errors.parkingStart}</small>
              )}
            </div>
            <div data-mdb-input-init className="form-outline mb-3">
              <label className="form-label" htmlFor="form4Example2">
                Parking to
              </label>
              <input
                name="parkingEnd"
                type="datetime-local"
                className="form-control"
                value={bookingData.parkingEnd}
                onChange={handleChange}
                min={new Date().toISOString().slice(0, 16)}
              />
              {errors.parkingEnd && (
                <small className="error-home">{errors.parkingEnd}</small>
              )}
              {errors.timeRestriction && (
                <small className="error-home">{errors.timeRestriction}</small>
              )}
            </div>
            <div data-mdb-input-init className="form-outline mb-3">
              <label className="form-label" htmlFor="form4Example1">
                Discount code
              </label>
              <input
                type="text"
                className="form-control"
                name="discountCode"
                placeholder="Enter your discount code"
                value={bookingData.discountCode}
                onChange={handleChange}
              />
            </div>
            <button
              data-mdb-ripple-init
              type="button"
              className="btn btn-primary btn-block mb-4 quote-btn"
              onClick={handleSubmit}
            >
              Quote
            </button>
          </form>
        </div>
      )}
      <div
        className="container-fluid d-flex justify-content-center align-items-center flex-column main-box booking-bg"
        id="booking"
      >
        <div className="container-fluid Services mx-2 my-2 slider-placement justify-content-center d-flex my-3">
          <div className="slider-box text-center">
            <h2 className="topheading">
              <b>Book your parking</b>
            </h2>
            <br />
            <p>"Your Perfect Parking Spot, Just a Click Away!"</p>
          </div>
        </div>
        <form className="row form-main-box" onSubmit={handleSubmit}>
          <div className="topleftbox"></div>
          <div className="bottomrightbox"></div>
          <div className="d-flex flex-column justify-content-center rounded-3 form-small-box">
            <label htmlFor="Location">
              <b>Location</b>
            </label>
            <select
              className="rounded px-2"
              id="airportSelect"
              onChange={handleOnAirportChange}
              value={bookingData.airportId || ''}
            >
              <option value="" disabled>
                Select an airport
              </option>
              {airports?.map((airport) => (
                <option key={airport.airportID} value={airport.airportID}>
                  {airport.airportName}
                </option>
              ))}
            </select>
            {errors.airportName && (
              <small className="booking-required">{errors.airportName}</small>
            )}
            <div className="datesbox">
              <label htmlFor="parkingStart">
                <b>Parking Start</b>
              </label>
              <input
                name="parkingStart"
                type="datetime-local"
                className="rounded input"
                value={bookingData.parkingStart}
                onChange={handleChange}
                min={new Date().toISOString().slice(0, 16)}
              />
              {errors.parkingStart && (
                <p className="booking-required">{errors.parkingStart}</p>
              )}
              <label htmlFor="parkingEnd">
                <b>Parking End</b>
              </label>
              <input
                name="parkingEnd"
                type="datetime-local"
                className="rounded input"
                value={bookingData.parkingEnd}
                onChange={handleChange}
                // min={new Date().toISOString().slice(0, 16)}
                min={bookingData.parkingStart}
              />
              {errors.parkingEnd && (
                <small className="booking-required">{errors.parkingEnd}</small>
              )}
            </div>
            {errors.timeRestriction && (
              <small className="booking-required">
                {errors.timeRestriction}
              </small>
            )}
            <div className="discount-box">
              <label htmlFor="discountCode">
                <b>Discount Code</b>
              </label>
              <input
                type="text"
                name="discountCode"
                placeholder="Enter your discount code"
                value={bookingData.discountCode}
                onChange={handleChange}
              />
            </div>
            <div className="button-boxQuote">
              <button type="submit" className="btn my-4">
                Book Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
