import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './testimonial.css'
import { API_BASE_URL } from '../../Config/Config'

const Testimonial = () => {
  const [testimonials, setTestimonial] = useState([])

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/CustomerFeedback/GetAllCustomerFeedback`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setTestimonial(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchTestimonial()
  }, [])

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    slidesToShow: 5,
    dots: true,
    infinite: true,
    arrows: false,
    pauseOnHover: true,
    slidesToScroll: 1,

    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
      { breakpoint: 425, settings: { slidesToShow: 1 } },
      { breakpoint: 375, settings: { slidesToShow: 1 } },
      { breakpoint: 320, settings: { slidesToShow: 1 } },
    ],
  }

  return (
    <>
      <h1 className="testimonial-heading" id="testimonial">
        Testimonials
      </h1>
      <div className="mb-5 testimonials-main-box">
        <Slider {...sliderSettings}>
          {testimonials?.map((item, index) => (
            <div className="slick-container">
              <div key={index} className="slick-item">
                <div className="testimonials-card">
                  <div className="img-card">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="testimonial">
                    {item.descriptionOfExperience?.length > 330
                      ? item.descriptionOfExperience.substring(0, 330) + '...'
                      : item.descriptionOfExperience}
                  </div>

                  <div className="testimonial-footer">
                    <a
                      href={`mailto:${item.email}`}
                      // target="_blank"
                      className="get-testimonial-link"
                    >
                      <b>{item.email}</b>
                    </a>
                    <div className="testimonial-profile">
                      {'⭐️'.repeat(item.overallSatisfaction)}
                    </div>
                  </div>

                  <div className="name">{item.name}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default Testimonial
