import "./../NewsLetter/news.css";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../Config/Config";
import CustomLoading, { Error } from "../Custom/LoadingAndError";

export default function News() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`${API_BASE_URL}/api/News/GetNews`, {
      method: "GET",
      headers: {
        // "ngrok-skip-browser-warning": "true",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  if (loading) return <CustomLoading />;
  if (error) return <Error />;

  return (
    <>
      <h1 className="news-heading">Latest News and Resources</h1>
      <div className="news-feed">
        {data?.map((news, index) => (
          <div key={index} className="news-item">
            <img src={news.imageUrl} alt={news.headline} />
            <div className="news-content">
              <h2>{news.headline}</h2>
              <p>{news.details}</p>
              <a href={news.link} target="_blank" className="read-more">
                Read More...
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
