import React from "react";
import "./LoadingAndError.css";

export default function CustomLoading() {
  return (
    <>
      <div className="loading-container">
        <div className="spinner"></div>
        <h1>Loading...</h1>
      </div>
    </>
  );
}

export function Error() {
  return (
    <>
      <div className="error-message">
        <h1>Error...</h1>
      </div>
    </>
  );
}
