import React, { useEffect, useState } from 'react'
import './Services.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import profile from './../../assets/users.png'
import Next from './../../assets/right-arrow.png'
import prev from './../../assets/back-arrow.png'
import Slider from 'react-slick'
import { API_BASE_URL } from '../../Config/Config'

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        bottom: '-50px',
        right: '50%',
        transform: 'translateX(50%)',
      }}
      onClick={onClick}
    ></div>
  )
}

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        bottom: '-50px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      onClick={onClick}
    ></div>
  )
}

export default function Services() {
  const [services, setServices] = useState([])

  useEffect(() => {
    async function getData() {
      const url = `${API_BASE_URL}/api/OurService/GetAllOurService`
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }

        const json = await response.json()
        setServices(json)
      } catch (error) {
        console.error(error.message)
      }
    }

    getData()
  }, [])
  const sliderRef = React.useRef(null)
  const settings = {
    dots: false,
    // infinite: true,
    speed: 500,
    // autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3, // Show only one card at a time
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 991, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 425, settings: { slidesToShow: 1 } },
      { breakpoint: 375, settings: { slidesToShow: 1 } },
      { breakpoint: 320, settings: { slidesToShow: 1 } },
    ],
  }

  return (
    <div className="services-container" id="services">
      <div className="slider-box">
        <h2 className="service-title">Our Services</h2>

        <Slider ref={sliderRef} {...settings} className="slider">
          {services?.map((service, index) => (
            <div className="services-card" key={index}>
              <div className="photo">
                <img
                  className="service-image"
                  src={API_BASE_URL + service.imageUrl}
                  alt="Description"
                />
              </div>
              <div className="data">
                <h6>{service.title}</h6>
                <p>{service.description}</p>
              </div>
              <div className="service-footer">
                <a href="#booking" className="get-service-link">
                  <b>Get Service</b>
                </a>
                <div className="profile">
                  <img
                    src={profile}
                    alt="Description"
                    className="profile-img-services"
                  />
                  <h6 className="service-count">{service.serviceCount}</h6>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="arrow-buttons-main-box">
          <div className="arrow-buttons">
            <button
              className="custom-prev-arrow"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <img className="arrow-img" src={prev} alt="Prev" />
            </button>

            <button
              className="custom-next-arrow"
              onClick={() => sliderRef.current.slickNext()}
            >
              <img className="arrow-img" src={Next} alt="Next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
