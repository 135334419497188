import React from "react";
import Privacy from "../Privacy/Privacy";
import Footer from "../Footer/footer";

function MergePrivacyComponent() {
  return (
    <div>
      <Privacy />
      <Footer />
    </div>
  );
}

export default MergePrivacyComponent;
