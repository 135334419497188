import { useEffect, useState } from 'react'
import './FAQs.css'
import Faq_logo from './../../assets/faq_new_logo.png'
import { API_BASE_URL } from '../../Config/Config'

const FAQs = () => {
  const [selected, setSelected] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const faqsPerPage = 6

  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    async function getData() {
      const url = `${API_BASE_URL}/api/FAQs/GetAllFAQs`
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json()
        setFaqs(json)
      } catch (error) {
        console.error(error.message)
      }
    }

    getData()
  }, [])

  const handleSingleSelection = (getCurrentId) => {
    setSelected(getCurrentId === selected ? null : getCurrentId)
  }

  const filteredData = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const totalPages = Math.ceil(filteredData.length / faqsPerPage)
  const currentFaqs = filteredData.slice(
    (currentPage - 1) * faqsPerPage,
    currentPage * faqsPerPage
  )

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1)
    } else {
      setCurrentPage(1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    } else {
      setCurrentPage(totalPages)
    }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    setCurrentPage(1) // Reset to the first page on new search
    setSelected('')
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // If there are results, don't submit but filter FAQs
    if (filteredData.length > 0) {
      return // No action needed if FAQs are found
    }

    // If no results, treat input as a new question
    if (!searchQuery.trim()) {
      alert('Please enter a question before submitting.')
      return
    }

    const url = `${API_BASE_URL}/api/FAQs/CreateFAQs`
    const payload = {
      question: searchQuery, // Using the search input as the question
      answer: null,
      category: 1,
      isDeleted: false,
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`Error submitting question: ${response.statusText}`)
      }

      alert('Question submitted successfully!')
      setSearchQuery('') // Clear the input field after submission
      // setFaqs([...faqs, payload]) // Optionally add the new question to the list
    } catch (error) {
      console.error(error.message)
      alert('Failed to submit your question. Please try again.')
    }
  }

  return (
    <div className="faq-container" id="faq">
      <h1>Frequently Ask Question</h1>
      <div className="faq-content">
        <div className="faq-left">
          <div className="faq-items">
            {currentFaqs.map((dataItem, index) => (
              <div key={dataItem.faQsID} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => handleSingleSelection(dataItem.faQsID)}
                >
                  <div>{dataItem.question}</div>
                  <span className="faq-toggle">
                    {selected === dataItem.faQsID ? '-' : '+'}
                  </span>
                </div>
                {selected === dataItem.faQsID && (
                  <div className="faq-answer">{dataItem.answer}</div>
                )}
              </div>
            ))}
          </div>
          <div className="faq-pagination">
            <button onClick={handlePrevPage}>{'<'}</button>
            <button onClick={handleNextPage}>{'>'}</button>
          </div>
        </div>
        <div className="faq-right">
          <div>
            <img src={Faq_logo} alt="" />
          </div>
          <h3>Any Questions?</h3>
          <p>You can search anything you want to know about ASKAII</p>
          <label htmlFor="faq">
            Let Me Know
            <input
              type="text"
              id="faq"
              placeholder="Ask a question"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="faqs-submit-button">
              <button type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </label>
        </div>
      </div>
    </div>
  )
}

export default FAQs
