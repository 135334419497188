import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import Navbar from '../NavBar/navbar'
import Home from '../Home/home'
import UserSubscription from '../UserSubscription/UserSubscription'
import Services from '../Services/Services'
import ImageDesc from '../ImageDesc/imageDesc'
import Team from '../OurTeam/Team'
import VideoDesc from '../VideoDesc/videoDesc'
import Testimonial from '../Testimonials/testimonial'
import FAQs from './../FAQs/FAQs'
import NewsLetter from '../NewsLetter/news'
import Find from '../FindUs/Find'
import Footer from '../Footer/footer'
import Modal from '../Modal/Modal'
import useModal from '../Modal/useModal'
import MergePrivacyComponent from './MergePrivacyComponent'
import MergeTermsComponents from './MergeTermsComponents'

import { BookingProvider, useBooking } from '../BookingContext/DataContext'
// import ProtectedBookings from '../BookingContext/ProtectedBookings'
import Quote from '../Booking/Quote/Quote'
import ProductOption from '../Booking/ProductOption/ProductOption'
import BookingDetail from '../Booking/BookingDetail/BookingDetail'
import PaymentMethods from '../Booking/PaymentMethods/PaymentMethods'
import CardDetails from '../Booking/CardDetails/CardDetails'
import CardPayment from '../Booking/CardPayment/CardPayment'
import ReturnURL from '../Booking/PaymentURL/ReturnURL'
import CancelURL from '../Booking/PaymentURL/CancelURL'
import AboutUs from '../Booking/About US/AboutUs'
import TermsAndConditions from '../Booking/TermsAndConditions/TermsAndConditions'
import ProtectedBookings from '../BookingContext/ProtectedBookings'
import useFetchAndRedirect from '../BookingContext/useFetchAndRedirect'

function App() {
  return (
    <BookingProvider>
      <Router>
        <Routes>
          <Route path="/" element={<AllComponentsWithRedirect />} />
          {/* <Route path="/product_option" element={<ProductOption />} />
          <Route path="/booking_detail" element={<BookingDetail />} /> */}

          <Route
            path="/product_option"
            element={
              <ProtectedBookings
                requiredData={['airportId', 'parkingStart', 'parkingEnd']}
                redirectTo="/"
              >
                <ProductOption />
              </ProtectedBookings>
            }
          />
          <Route
            path="/booking_detail"
            element={
              <ProtectedBookings
                requiredData={['outBoundTerminalId', 'inBoundTerminalId']}
                redirectTo="/product_option"
              >
                <BookingDetail />
              </ProtectedBookings>
            }
          />
          <Route path="/payment_method" element={<PaymentMethods />} />
          <Route path="/card_detail" element={<CardDetails />} />
          <Route path="/card_payment" element={<CardPayment />} />

          <Route path="/about-booking" element={<AboutUs />} />

          <Route path="/privacy-policy" element={<MergePrivacyComponent />} />
          <Route path="/terms-conditions" element={<MergeTermsComponents />} />
          <Route path="/return-url" element={<ReturnURL />} />
          <Route path="/cancel-url" element={<CancelURL />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
      </Router>
    </BookingProvider>
  )
}
function AllComponentsWithRedirect() {
  useFetchAndRedirect() // Now runs within Router and BookingProvider

  return <AllComponents />
}
function AllComponents() {
  const { isOpen, closeModal } = useModal()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal} />
      <Navbar />
      <Home />
      <UserSubscription />
      <Quote />
      <Services />
      <ImageDesc />
      <Team />
      <VideoDesc />
      <Testimonial />
      <FAQs />
      <NewsLetter />
      <Find />
      <Footer />
    </div>
  )
}

export default App
