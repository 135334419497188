import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'

const BookingContext = createContext()

export function useBooking() {
  return useContext(BookingContext)
}

export function BookingProvider({ children }) {
  const [bookingData, setBookingData] = useState(() => {
    return {
      // data of quote
      quoteCode: '',
      airportName: 'Heathrow Airport',
      airportId: 72,
      airportCode: '',
      parkingStart: '',
      parkingEnd: '',
      discountCode: '',
      totalDays: 0,
      totalDaysCost: 0,
      totalCostAll: 0,

      // data of prodcut option
      outBoundTerminalName: '',
      outBoundTerminalId: '',
      outBoundTerminalCost: 0,

      inBoundTerminalName: '',
      inBoundTerminalId: '',
      inBoundTerminalCost: 0,

      carCleanName: 'None',
      carCleanId: null,
      carCleanCost: 0,

      electricChargeName: 'None',
      electricChargeId: null,
      electricChargeCost: 0,
      // data of booking Detail
      userIsRegister: false,
      promotions: false,
      userTitle: '',
      userFirstName: '',
      userSurName: '',
      email: '',
      userCompanyName: '',
      userMobileNo: '',

      userSource: '',
      otherUserSource: '',

      carRegistrationNo: '',

      carMakeName: '',
      carMakeId: '',

      carOtherMakeName: '',
      carOtherMakeId: '',

      carModelName: '',
      carModelId: '',

      carOtherModelName: '',
      carOtherModelId: '',

      carColourName: '',
      carColourId: '',

      carOtherColourName: '',
      carOtherColourId: '',

      flyingTo: '',
      outBoundFlightNo: '',
      inBoundFlightNo: '',
      isDifferentFromAirport: false,
      flyingFrom: '',
      isAgreeOnTerms: '',

      // data of payment method
      paymentMethod: 'card',

      postCode: '',
      address: '',
      town: '',
      county: '',
      country: 'United Kingdom',

      paymentState: '',

      customerId: '',
      toggleDetails: false,
    }
  })

  const [cardData, setCardData] = useState({
    creditCard: {
      name: '',
      cardNumber: '',
      expirationYear: '',
      expirationMonth: '',
      cvc: '',
    },
  })

  return (
    <BookingContext.Provider
      value={{ bookingData, setBookingData, cardData, setCardData }}
    >
      {children}
    </BookingContext.Provider>
  )
}
