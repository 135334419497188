import React from "react";
import "./CancelURL.css";
import Paymentcancel from "./../../../assets/cross.png";

function CancelURL() {
  function handleClose() {
    window.history.replaceState(null, null, "/");

    window.history.pushState(null, null, "/");

    window.location.replace("/");
  }
  return (
    <div>
      <div className="payment-success-container-main">
        <div className="payment-success-container">
          <img src={Paymentcancel} alt="Description of image" />
          <h1>Payment Cancelled</h1>
          <p>Your transaction has been cancelled successfully!</p>
          <div className="actions9">
            {
              <button onClick={handleClose} className={"pay-now-btn9"}>
                Close
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelURL;
