import React from "react";
import Footer from "../Footer/footer.jsx";
import Terms from "../Terms/Terms.jsx";

function MergeTermsComponents() {
  return (
    <div>
      <Terms />
      <Footer />
    </div>
  );
}

export default MergeTermsComponents;
