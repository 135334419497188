// import React from "react";
// import "./videoDesc.css";

// export default function video() {
//   const videoId = "6K2mCuulB44";
//   const videoUrl = `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&mute=1&loop=1&modestbranding=0&playlist=${videoId}`;

//   return (
//     <>
//       <div className="d-flex flex-row justify-content-center align-items-center video-main-box mainvidio-box">
//         <div className="d-flex justify-content-center align-items-center text-box">
//           <div className="text-main-heading-box">
//             <div className="text-heading-box">
//               <h4>
//                 Everything you can do in a physical classroom,{" "}
//                 <span>you can do with TOTC</span>
//               </h4>
//             </div>
//             <p>
//               TOTC’s school management software helps traditional and online
//               schools manage scheduling, attendance, payments and virtual
//               classrooms all in one secure cloud-based system.
//             </p>
//             <h3>
//               <a href="https://www.youtube.com" target="_blank">
//                 Watch more videos
//               </a>
//             </h3>
//           </div>
//         </div>

//         <div className="d-flex justify-content-center align-items-center text-start img-box videocontainer">
//           <div className="topleft"></div>
//           <div className="bottomright"></div>

//           <div className="video-container">
//             <iframe
//               width="600"
//               height="400"
//               src={videoUrl}
//               frameBorder="0"
//               allow="0"
//               allowFullScreen
//               title="TOTC Video"
//               autoPlay
//               loop
//               muted
//             ></iframe>
//           </div>
//           <div className="video-container"></div>
//         </div>
//       </div>
//     </>
//   );
// }
import React from "react";
import "./videoDesc.css";

function VideoDesc() {
  const videoId = "Iw_48YlMC48";
  const videoUrl = `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=${videoId}`;
  return (
    <>
      <div className="container video-section-parent">
        <div className="row video-section-parent">
          <div className="col-6 col-md-12 parent-left-video">
            <h1>
              Everything you expect from an exceptional parking experience,
              <span> you can achieve with ASKAII.</span>
            </h1>
            <p>
              Whether it's effortless booking, reliable availability, or
              additional amenities, ASKAII is dedicated to delivering a superior
              level of service that turns your parking challenges into a
              seamless part of your travel journey.
            </p>
            {/* <a href="#">Learn more</a> */}
          </div>
          <div className="col-6 col-md-12 parent-right-video">
            {/* <div className="text-vidio-section-right">
        <div className="text-vidio-section-right-center"> */}
            {/* <div className="right-vidio-top"></div>
          <div className="right-vidio-right"></div> */}
            {/* <div className="right-vidio-midel"> */}
            <div className="left-box-video"></div>
            <div className="right-box-video"></div>
            <div className="wrapper-iframe">
              <iframe
                src={videoUrl}
                frameborder="10px"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="TOTC Video"
              ></iframe>
            </div>
            <div className="wrapper-iframe"></div>
            {/* </div> */}
            {/* <div className="right-vidio-midel"></div> */}
          </div>
          {/* </div>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default VideoDesc;
