import React from 'react'

const BookingLoader = () => {
  return (
    <div className="modal-overlay">
      {/* <div className="modal"> */}
      <div className="spinner"></div>
      {/* </div> */}
    </div>
  )
}

export default BookingLoader
