import React, { useEffect, useState } from 'react'
import BookingLoader from '../../BookingLoader/LoadingAndError'
import BookingNavbar from './../BookingNavbar/BookingNavbar'

import BookingDetailSection from '../BookingDetailSection/BookingDetailSection'
import BookingCalculatorSection from '../BookingCalculatorSection/BookingCalculatorSection'
import BookingNavigationBtn from '../BookingNavigationBtn/BookingNavigationBtn'
import { useBooking } from '../../BookingContext/DataContext'
import { useNavigate } from 'react-router-dom'
import { API_BASE_URL } from '../../../Config/Config'
import './CardDetails.css'

const CardDetails = () => {
  const navigate = useNavigate()
  const { bookingData, setBookingData, cardData, setCardData } = useBooking()
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({})

  const handleCardChange = (e) => {
    const { name, value } = e.target

    let newValue = value
    if (name === 'cardNumber') {
      newValue = value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
    } else if (name === 'expirationMonth' || name === 'expirationYear') {
      newValue = value.replace(/\D/g, '')
    } else if (name === 'cvc') {
      newValue = value.replace(/\D/g, '')
    } else if (name === 'name') {
      newValue = value.replace(/[^a-zA-Z\s]/g, '')
    }

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        [name]: newValue,
      },
    }))
  }

  const handleCardNumberChange = (e) => {
    let { value } = e.target
    // Remove any non-digit characters
    const rawValue = value.replace(/\D/g, '')

    // Insert space after every 4 digits for display purposes
    const formattedValue = rawValue.replace(/(\d{4})(?=\d)/g, '$1 ')

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        cardNumber: rawValue,
      },
    }))
  }

  const handleExpiryChange = (e) => {
    let { value } = e.target
    // Remove any non-numeric characters except slash
    value = value.replace(/\D/g, '')

    // Insert slash after every 2 digits (MM/YY)
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2)
    }

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        expirationMonth: value.slice(0, 2),
        expirationYear: value.slice(3, 5),
      },
    }))
  }

  const validate = () => {
    const newErrors = {}
    const { name, cardNumber, expirationMonth, expirationYear, cvc } =
      cardData.creditCard

    // Validate card number
    const cleanedCardNumber = cardNumber.replace(/-/g, '')
    if (cleanedCardNumber.length !== 16) {
      newErrors.cardNumber = 'Card number must be 16 digits'
    } else if (!luhnCheck(cleanedCardNumber)) {
      newErrors.cardNumber = 'Invalid card number'
    }

    // Validate expiry
    if (expirationMonth.length !== 2 || expirationYear.length !== 2) {
      newErrors.expiry = 'Expiry date must be in MM/YY format'
    } else {
      const month = parseInt(expirationMonth, 10)
      const year = parseInt(expirationYear, 10)
      if (month < 1 || month > 12) {
        newErrors.expirationMonth = 'Invalid expiry month'
      }
      if (year < new Date().getFullYear() % 100) {
        newErrors.expirationYear = 'Card has expired'
      }
    }

    // Validate security code
    if (cvc.length < 3 || cvc.length > 5) {
      newErrors.cvc = 'Security code must be correct'
    }

    // Validate cardholder name
    if (name.trim() === '') {
      newErrors.name = 'Cardholder name cannot be empty'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const luhnCheck = (num) => {
    let arr = (num + '')
      .split('')
      .reverse()
      .map((x) => parseInt(x))
    let sum = arr.reduce((acc, val, i) => {
      if (i % 2 === 0) {
        return acc + val
      } else {
        let double = val * 2
        return acc + (double > 9 ? double - 9 : double)
      }
    }, 0)
    return sum % 10 === 0
  }

  const handleBack = () => {
    navigate('/booking_six')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (validate()) {
      setLoading(true)
      const { name, cardNumber, expirationMonth, expirationYear, cvc } =
        cardData.creditCard
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/AddCustomer`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: bookingData.email,
              name: bookingData.userSurName,
              creditCard: {
                name,
                cardNumber: cardNumber.replace(/-/g, ''),
                expirationYear,
                expirationMonth,
                cvc,
              },
            }),
          }
        )
        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        const data = await response.json()
        setBookingData((prevData) => ({
          ...prevData,
          customerId: data.customerId,
        }))
        setLoading(false)
        navigate('/card_payment')
      } catch (err) {
        console.error(err.message)
        setLoading(false)
      }
    }
  }

  return (
    <>
      {/* {loading && <BookingLoader />} */}

      <div className="main-container-card_details">
        <div className="main-card_details">
          <BookingNavbar />
          <div className="card_details-option-container">
            <BookingDetailSection />

            {/* Product and Calculator Section */}
            <div className="card_details-section">
              <div className="card_details-heading">Card Details</div>
              <div className="card_detail-option">
                <label className="card_detail-label">
                  Card Number: <span className="booking-required">*</span>
                </label>
                <input
                  className="card_detail-select"
                  type="text"
                  name="cardNumber"
                  value={cardData.creditCard.cardNumber.replace(
                    /(\d{4})(?=\d)/g,
                    '$1 '
                  )}
                  onChange={handleCardNumberChange}
                  maxLength="19"
                />
                {errors.cardNumber && (
                  <p className="booking-required">{errors.cardNumber}</p>
                )}
              </div>
              <div className="card_detail-option">
                <label className="card_detail-label">
                  Card Expiry: <span className="booking-required">*</span>
                </label>
                <input
                  className="card_detail-select "
                  type="text"
                  name="expiry"
                  value={`${cardData.creditCard.expirationMonth || ''}/${
                    cardData.creditCard.expirationYear || ''
                  }`}
                  onChange={handleExpiryChange}
                  maxLength="5"
                />
                {errors.expiry && (
                  <p className="booking-required">{errors.expiry}</p>
                )}
              </div>{' '}
              <div className="card_detail-option">
                <label className="card_detail-label">
                  Security Code: <span className="booking-required">*</span>
                </label>
                <input
                  className="card_detail-select"
                  type="text"
                  name="cvc"
                  value={cardData.creditCard.cvc}
                  onChange={handleCardChange}
                  maxLength="4"
                />
                {errors.cvc && <p className="booking-required">{errors.cvc}</p>}
              </div>{' '}
              <div className="card_detail-option">
                <label className="card_detail-label">
                  Cardholder Name: <span className="booking-required">*</span>
                </label>
                <input
                  className="card_detail-select"
                  type="text"
                  name="name"
                  value={cardData.creditCard.name}
                  onChange={handleCardChange}
                />
                {errors.name && (
                  <p className="booking-required">{errors.name}</p>
                )}
              </div>
            </div>
            <BookingCalculatorSection />
          </div>
          <BookingNavigationBtn
            handleBack={handleBack}
            handleNext={handleNext}
          />
        </div>
      </div>
    </>
  )
}

export default CardDetails
