import React, { useEffect, useState } from 'react'
import BookingLoader from '../../BookingLoader/LoadingAndError'
import BookingNavbar from './../BookingNavbar/BookingNavbar'

import BookingDetailSection from '../BookingDetailSection/BookingDetailSection'
import BookingCalculatorSection from '../BookingCalculatorSection/BookingCalculatorSection'
import BookingNavigationBtn from '../BookingNavigationBtn/BookingNavigationBtn'
import { useBooking } from '../../BookingContext/DataContext'
import { useNavigate } from 'react-router-dom'
import { API_BASE_URL } from '../../../Config/Config'
import './PaymentMethods.css'

const PaymentMethods = () => {
  const { bookingData, setBookingData } = useBooking()
  const [countries, setCountries] = useState()
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/booking_detail')
  }

  const handleCreatePaypal = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/CreatePayment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: bookingData.totalCostAll,
            currency: 'GBP',
            returnUrl: 'http://localhost:3000/return-url',
            cancelUrl: 'http://localhost:3000/cancel-url',
            BookingId: 1,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to fetch quote')
      }
      const data = await response.json()
      console.log(data)
      setLoading(false)

      const width = window.screen.width
      const height = window.screen.height
      const newWindow = window.open(
        data.links[1].href,
        '_blank',
        `noopener,noreferrer,width=${width},height=${height}`
      )

      if (newWindow) {
        newWindow.focus()
      }
    } catch (err) {
      // setError(err.message);
      setLoading(false)
    } finally {
      setLoading(false)
      // setLoading(false);
    }
    // navigate('/')
  }

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `https://restcountries.com/v3.1/all?fields=name`,
          {
            method: 'GET',
            headers: {
              // "ngrok-skip-browser-warning": "true",
              'Content-Type': 'application/json',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setCountries(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchCountries()
  }, [])

  const handlePaymentChange = (e) => {
    const { name, type, value, checked } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]:
        type === 'checkbox'
          ? checked
          : value === 'true'
          ? true
          : value === 'false'
          ? false
          : value,
    }))
  }

  const handleBillingAddress = (e) => {
    const { name, value } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const validateForm = () => {
    let formErrors = {}
    if (!bookingData.postCode) formErrors.postCode = 'Postcode is required'
    if (!bookingData.address) formErrors.address = 'Address is required'
    if (!bookingData.town) formErrors.town = 'Town/City is required'

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const handleNext = () => {
    if (validateForm()) {
      navigate('/card_detail')
    }
  }
  return (
    <>
      {loading && <BookingLoader />}

      <div className="main-container-payment_methods">
        <div className="main-payment_methods">
          <BookingNavbar />
          <div className="payment_methods-option-container">
            <BookingDetailSection />

            {/* Product and Calculator Section */}
            <div className="payment_methods-section">
              <div className="payment_methods-heading">Payment Methods</div>

              <div className="payment_method-option">
                <label className="payment_method-label">Payment Methods:</label>
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="card"
                    onChange={handlePaymentChange}
                    checked={bookingData.paymentMethod === 'card'}
                  />{' '}
                  Payment By Card
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="paypal"
                    onChange={handlePaymentChange}
                    checked={bookingData.paymentMethod === 'paypal'}
                  />{' '}
                  PayPal
                </label>
              </div>
              {bookingData.paymentMethod === 'card' && (
                <>
                  <div className="payment_methods-heading">Billing Address</div>
                  <div className="payment_method-option">
                    <label className="payment_method-label">
                      Postcode: <span className="booking-required">*</span>
                    </label>
                    <input
                      className="payment_method-select"
                      type="text"
                      name="postCode"
                      value={bookingData.postCode}
                      onChange={handleBillingAddress}
                    />
                    {errors.postCode && (
                      <p className="booking-required">{errors.postCode}</p>
                    )}
                  </div>
                  <div className="payment_method-option">
                    <label className="payment_method-label">
                      Address: <span className="booking-required">*</span>
                    </label>
                    <textarea
                      className="payment_method-select"
                      name="address"
                      value={bookingData.address}
                      onChange={handleBillingAddress}
                    ></textarea>
                    {errors.address && (
                      <p className="booking-required">{errors.address}</p>
                    )}
                  </div>
                  <div className="payment_method-option">
                    <label className="payment_method-label">
                      Town/City: <span className="booking-required">*</span>
                    </label>
                    <input
                      className="payment_method-select"
                      type="text"
                      name="town"
                      value={bookingData.town}
                      onChange={handleBillingAddress}
                    />
                    {errors.town && (
                      <p className="booking-required">{errors.town}</p>
                    )}
                  </div>
                  <div className="payment_method-option">
                    <label className="payment_method-label">County:</label>
                    <input
                      className="payment_method-select"
                      type="text"
                      name="county"
                      value={bookingData.county}
                      onChange={handleBillingAddress}
                    />
                  </div>
                  <div className="payment_method-option">
                    <label className="payment_method-label">Country:</label>
                    <select
                      className="payment_method-select"
                      name="country"
                      value={bookingData.country}
                      onChange={handleBillingAddress}
                    >
                      {countries?.map((country, index) => (
                        <option
                          selected={country.name.common === 'United Kingdom'}
                          key={index}
                        >
                          {country.name.common}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {bookingData.paymentMethod === 'paypal' && (
                <>
                  <div className="payment_methods-heading">
                    {/* Click continue to go on PayPal */}
                  </div>
                </>
              )}
            </div>
            <BookingCalculatorSection />
          </div>
          <BookingNavigationBtn
            handleBack={handleBack}
            handleNext={
              bookingData.paymentMethod === 'paypal'
                ? handleCreatePaypal
                : handleNext
            }
            btnText={
              bookingData.paymentMethod === 'paypal' ? 'Continue' : 'Next'
            }
          />
        </div>
      </div>
    </>
  )
}

export default PaymentMethods
