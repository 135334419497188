import React from "react";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <>
      {/* <div className="max-w-4xl mx-auto p-4 bg-white dark:bg-card rounded-lg shadow-md ">
        <h3 className="  mb-4">On your way to the Airport:</h3>
        <p className="text-muted-foreground mb-4">
          We ask our customers to call the Duty Controller on 020 8759 5252 when
          approximately 30 minutes from reaching the terminal and we can inform
          you when a uniformed ASKAII driver will be available.
        </p>
        <h3 className=" mt-6">
          Meeting an ASKAII Meet and Greet Representative:
        </h3>
        <p className="text-muted-foreground mb-4">
          We always meet you at your vehicle. Your driver will assist you with
          your luggage and to help you to prepare for the next stage of your
          journey. We feel that this is much more personal than meeting you away
          from your car in the lift lobby.
        </p>
        <h3 className="text-lg font-semibold  mt-6">Why the photos?</h3>
        <p className="text-muted-foreground mb-4">
          When you meet your ASKAII driver, they will carry out a cursory check of
          the vehicle, we always encourage customers to take detailed photos of
          their vehicle from all angles. This is purely for your peace of mind
          and it is always good practice when leaving your car in someone else's
          care.
        </p>
        <h3 className="text-lg font-semibold  mt-6">
          ASKAII parking your vehicle:
        </h3>
        <p className="text-muted-foreground mb-4">
          Whilst your car is in our care, we will look after it as if it is our
          own. Our drivers are insured on a Comprehensive basis and they will
          drive your vehicle back to our secure car park for safe storage. Your
          vehicle is fully insured for the entire duration, on-site cover is
          included. Our car park has CCTV on entry, 24-hour security and there
          is no public access.
        </p>
        <h3 className="text-lg font-semibold  mt-6">When you return:</h3>
        <p className="text-muted-foreground mb-4">
          After you have collected all of your luggage, call the Duty Controller
          on 020 8759 5252 and we'll despatch your vehicle. Please check your
          vehicle whilst ASKAII staff are with you, we cannot accept liability for
          any issues reported once your vehicle has been removed from our care.
          ASKAII will pay for 15 minutes of parking for your return and additional
          costs will need to be paid by the customer. When the vehicle has been
          signed back to you, staff will validate your exit ticket and you can
          make your way home!
        </p>
      </div> */}
      <div className="container-terms">
        <div className="section">
          <h2>On your way to the Airport:</h2>
          <p>
            We ask our customers to call the Duty Controller on 020 8759 5252
            when approximately 30 minutes from reaching the terminal and we can
            inform you when a uniformed ASKAII driver will be available.
          </p>
        </div>
        <div className="section">
          <h2>Meeting an ASKAII Meet and Greet Representative:</h2>
          <p>
            We always meet you at your vehicle. Your driver will assist you with
            your luggage and to help you to prepare for the next stage of your
            journey. We feel that this is much more personal than meeting you
            away from your car in the lift lobby.
          </p>
          <p>
            Meeting you at your vehicle allows you the opportunity to check your
            vehicle over with staff and you can take photos of your car while
            our staff member is still present. Also remember to double check you
            have everything you need for your trip!
          </p>
        </div>
        <div className="section">
          <h2>Why the photos?</h2>
          <p>
            When you meet your ASKAII driver, they will carry out a cursory
            check of the vehicle, we always encourage customers to take detailed
            photos of their vehicle from all angles. This is purely for your
            peace of mind and it is always good practice when leaving your car
            in someone else's care.
          </p>
        </div>
        <div className="section">
          <h2>ASKAII parking your vehicle:</h2>
          <p>
            Whilst your car is in our care, we will look after it as if it is
            our own. Our drivers are insured on a Comprehensive basis and they
            will drive your vehicle back to our secure car park for safe
            storage. Your vehicle is fully insured for the entire duration,
            on-site cover is included. Our car park has CCTV on entry, 24 hour
            security and there is no public access.
          </p>
        </div>
        <div className="section">
          <h2>When you return:</h2>
          <p>
            After you have collected all of your luggage, call the Duty
            Controller on 020 8759 5252 and we'll despatch your vehicle. Please
            check your vehicle whilst ASKAII staff are with you, we cannot
            accept liability for any issues reported once your vehicle has been
            removed from our care.
          </p>
          <p>
            ASKAII will pay for 15 minutes of parking for your return and
            additional costs will need to be paid by the customer. When the
            vehicle has been signed back to you, staff will validate your exit
            ticket and you can make your way home!
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
