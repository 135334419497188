import { useBooking } from '../../BookingContext/DataContext'
import './BookingDetailSection.css'

const BookingDetailSection = () => {
  const { bookingData } = useBooking()

  return (
    <div className="booking-details-section">
      <div className="booking-details-heading">Booking Details</div>
      <p className="booking-details-item">Airport Name:</p>
      <span>{bookingData.airportName}</span>
      <p className="booking-details-item">Parking Start:</p>
      <span>{bookingData.parkingStart}</span>
      <p className="booking-details-item">Parking End:</p>
      <span>{bookingData.parkingEnd}</span>
      {bookingData.discountCode ? (
        <>
          <p className="booking-details-item">Discount Code:</p>
          <span>{bookingData.discountCode}</span>
        </>
      ) : (
        ''
      )}
      <p className="booking-details-item">Total Time:</p>
      <span>
        {bookingData.totalDays}
        {bookingData.totalDays > 1 ? ` Days` : ' Day'}
      </span>
      <p className="booking-details-item">Total Amount:</p>
      <span>{bookingData.totalDaysCost}£</span>
      {/* {bookingData.outBoundTerminalId ? (
        <>
          <p className="booking-details-item">Outbound Terminal:</p>
          <span>{bookingData.outBoundTerminalName}</span>
        </>
      ) : (
        ''
      )}
      {bookingData.inBoundTerminalId ? (
        <>
          <p className="booking-details-item">Inbound Terminal:</p>
          <span>{bookingData.inBoundTerminalName}</span>
        </>
      ) : (
        ''
      )}
      {bookingData.email ? (
        <>
          <p className="booking-details-item">Email:</p>
          <span>{bookingData.email}</span>
        </>
      ) : (
        ''
      )}{' '}
      {bookingData.surName ? (
        <>
          <p className="booking-details-item">Customer Name:</p>
          <span>
            {bookingData.firstName}
            {bookingData.surName}
          </span>
        </>
      ) : (
        ''
      )}{' '}
      {bookingData.inBoundTerminalId ? (
        <>
          <p className="booking-details-item">Inbound Terminal:</p>
          <span>{bookingData.inBoundTerminalName}</span>
        </>
      ) : (
        ''
      )}{' '}
      {bookingData.inBoundTerminalId ? (
        <>
          <p className="booking-details-item">Inbound Terminal:</p>
          <span>{bookingData.inBoundTerminalName}</span>
        </>
      ) : (
        ''
      )}{' '}
      {bookingData.inBoundTerminalId ? (
        <>
          <p className="booking-details-item">Inbound Terminal:</p>
          <span>{bookingData.inBoundTerminalName}</span>
        </>
      ) : (
        ''
      )} */}
    </div>
  )
}

export default BookingDetailSection
