import React from "react";
import "./Find.css";
import location from "./../../assets/location.png";
import phone from "./../../assets/iphone.png";
import email from "./../../assets/mail.png";

export default function Contactus() {
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center  flex-column section-find"
        id="find"
      >
        <h1>
          Find Us
        </h1>
        <p>
          We’d love to hear from you! Whether you have questions, feedback, or
          need assistance, feel free to reach out.
        </p>

        <div class="container contactus-box text-center d-flex flex-column">
          <div className="items d-flex flex-row justify-content-center align-items-center">
            <div className="location-box">
              <img className="2-mx" src={location} alt="Description" />
              <h5>Address</h5>
              <a href="/">
                {" "}
                <p>Office 7, 8, 9 Teletower, Model Town Link Road, Lahore</p>
              </a>
            </div>
            <div className="phonenumber-box">
              <img className="2-mx" src={phone} alt="Description" />
              <h5>Phone Number</h5>
              <a href="/">
                {" "}
                <p>+1 (322) 482-4998</p>
              </a>
            </div>
            <div className="email-box">
              <img className="2-mx" src={email} alt="Description" />
              <h5>Email</h5>
              <a href="/">
                {" "}
                <p>info@huboweb.com</p>
              </a>
            </div>
          </div>
        </div>
        <iframe
          title="Google Map"
          src="https://maps.google.com/maps?q=Office+7%2C+8%2C+9+Teletower%2C+Model+Town+Link+Road%2C+Lahore&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          width="100%"
          height="500px"
          allowFullScreen="true"
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </>
  );
}
