import React from "react";
import "./../ImageDesc/imageDesc.css";
import Image from "./../../assets/key-in-safe-hand2.png";

export default function imageDesc() {
  return (
    <div className="container-fluid sec-placement" id="service">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <img
            src={Image}
            className="img-placement"
            alt="Key-In-Safe-Hand.png"
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <h1 className="desc-title">
            <span className="span-bg">Your </span>car is in safe hand
          </h1>
          <p className="desc-content">
            Rest easy knowing your vehicle is in our secure hands. At our
            airport car park, we prioritize your car's safety with 24/7
            surveillance and rigorous security measures. Choose us for peace of
            mind and trust that your vehicle is protected from the moment you
            leave it with us. Our state-of-the-art security system includes
            constant CCTV monitoring, trained on-site security personnel,
            controlled access for authorized individuals, and regular patrols to
            add an extra layer of protection. With our commitment to safety, you
            can travel worry-free, knowing that your vehicle is in good hands.
            Whether you're on a short trip or a long journey, our secure parking
            services ensure your car remains safe and sound. Choose our airport
            car park for unparalleled security and exceptional service.
          </p>
        </div>
      </div>
    </div>
  );
}
