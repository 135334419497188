import './BookingNavigationBtn.css'

const BookingNavigationBtn = ({ handleBack, handleNext, btnText = 'Next' }) => {
  return (
    <div className="navigation-buttons-section">
      <button className="navigation-button" onClick={handleBack}>
        Previous
      </button>
      <button className="navigation-button" onClick={handleNext}>
        {btnText}
      </button>
    </div>
  )
}

export default BookingNavigationBtn
