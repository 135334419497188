import React, { useState } from 'react'
import { API_BASE_URL } from '../../../Config/Config'
import { useBooking } from '../../BookingContext/DataContext'
import './CardPayment.css'
import Paymentok from './../../../assets/check (1).png'
import Paymentcancel from './../../../assets/cross.png'
import { NavLink } from 'react-router-dom'
import BookingLoader from '../../BookingLoader/LoadingAndError'

export default function CardPayment() {
  const [visibleComponent, setVisibleComponent] = useState('first')
  const { bookingData, setBookingData, cardData } = useBooking()

  const [loading, setLoading] = useState(false)
  async function confirmPayment() {
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/Booking/AddPayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: bookingData.customerId,
          receiptEmail: bookingData.email,
          description: 'string',
          currency: 'GBP',
          amount: bookingData.totalCostAll * 100,
        }),
      })
      if (!response.ok) {
        throw new Error('error')
      }
      const data = await response.json()
      setLoading(false)
    } catch (err) {
      console.error(err.message)
      setLoading(false)
    }
    setVisibleComponent('success')
  }
  function cancelPayment() {
    setVisibleComponent('cancel')
    // navigate("/booking_nine");
  }

  function handleClose() {
    window.history.replaceState(null, null, '/')

    window.history.pushState(null, null, '/')

    window.location.replace('/')
  }

  return (
    <>
      {loading && <BookingLoader />}

      {visibleComponent === 'first' && (
        <div>
          <div className="booking-eight-main-box">
            <div className="booking-eight-inner-box">
              <div className="booking-eight-inner-box-heading">
                <h6>Confirm your payment</h6>
                <p>Quickly and secure, free transactions.</p>
              </div>
              <div className="booking-eight-inner-box-middel">
                <div className="booking-eight-inner-box-middel-data">
                  <h6>Details</h6>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Date</p>
                    <span>
                      {new Date().toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </span>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Payment methed</p>
                    <p>Card</p>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Card number</p>
                    <p>
                      **** **** ****{' '}
                      {cardData?.creditCard.cardNumber[12] +
                        cardData?.creditCard.cardNumber[13] +
                        cardData?.creditCard.cardNumber[14] +
                        cardData?.creditCard.cardNumber[15]}
                    </p>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Cardholder name</p>
                    <p>{cardData?.creditCard.name}</p>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Email</p>
                    <p>{bookingData?.email}</p>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line-line">
                    <hr />
                  </div>
                  {bookingData?.toggleDetails ? (
                    <div className="calculator-values">
                      <div className="single-value">
                        <span>{bookingData.airportCode} Flexible</span>
                        <span>£{bookingData.totalDaysCost}</span>
                      </div>
                      <div className="single-value">
                        <span>
                          {bookingData.outBoundTerminalName &&
                          bookingData.outBoundTerminalCost
                            ? `Outbound Terminal: ${bookingData.outBoundTerminalName}`
                            : ''}
                        </span>
                        <span>
                          {bookingData.outBoundTerminalName &&
                          bookingData.outBoundTerminalCost
                            ? `£${bookingData.outBoundTerminalCost}`
                            : ''}
                        </span>
                      </div>
                      <div className="single-value">
                        <span>
                          {bookingData.inBoundTerminalName &&
                          bookingData.inBoundTerminalCost
                            ? `Inbound Terminal: ${bookingData.inBoundTerminalName}`
                            : ''}
                        </span>
                        <span>
                          {bookingData.inBoundTerminalName &&
                          bookingData.inBoundTerminalCost
                            ? `£${bookingData.inBoundTerminalCost}`
                            : ''}
                        </span>
                      </div>
                      <div className="single-value">
                        <span>
                          {bookingData.carCleanName && bookingData.carCleanCost
                            ? bookingData.carCleanName
                            : ''}
                        </span>
                        <span>
                          {bookingData.carCleanName && bookingData.carCleanCost
                            ? `£${bookingData.carCleanCost}`
                            : ''}
                        </span>
                      </div>
                      <div className="single-value">
                        <span>
                          {bookingData.electricChargeName &&
                          bookingData.electricChargeCost
                            ? bookingData.electricChargeName
                            : ''}
                        </span>
                        <span>
                          {bookingData.electricChargeName &&
                          bookingData.electricChargeCost
                            ? `£${bookingData.electricChargeCost}`
                            : ''}
                        </span>
                      </div>
                      <div className="total-value">
                        <span></span>
                        <span>£{bookingData.totalCostAll}</span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Total amount</p>
                    <p>£{bookingData.totalCostAll}</p>
                  </div>
                </div>
              </div>

              <div className="booking-eight-inner-box-bottom">
                <p
                  type="button"
                  onClick={() =>
                    setBookingData((prevData) => ({
                      ...prevData,
                      toggleDetails: !bookingData.toggleDetails,
                    }))
                  }
                >
                  {bookingData?.toggleDetails ? 'Hide' : 'Show'} details
                </p>

                <div className="booking-eight-inner-box-bottom-button">
                  <button onClick={cancelPayment}>Cancel Payment</button>
                  {/* <button onClick={confirmPayment}>Confirm Payment</button> */}
                  <button onClick={confirmPayment}>Confirm Payment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {visibleComponent === 'success' && (
        <div>
          <div className="payment-success-container-main">
            <div className="payment-success-container">
              <img src={Paymentok} alt="success payment" />
              <h1>Payment Success</h1>
              <p>
                You just make a transaction to{' '}
                <NavLink to={'/'}>Askaii.com</NavLink> £
                {bookingData.totalCostAll} from your wallet
              </p>
              <div className="actions9">
                {
                  <button onClick={handleClose} className={'pay-now-btn9'}>
                    Close
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
      {visibleComponent === 'cancel' && (
        <div>
          <div className="payment-success-container-main">
            <div className="payment-success-container">
              <img src={Paymentcancel} alt="cancel payment" />
              <h1>Payment Cancelled</h1>
              <p>Your transaction has been cancelled successfully!</p>
              <div className="actions9">
                {
                  <button onClick={handleClose} className={'pay-now-btn9'}>
                    Close
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
