// import BookingNavbar from '../BookingNavbar'
import BookingNavbar from './../BookingNavbar/BookingNavbar'
import './ProductOption.css'
import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { useBooking } from '../../BookingContext/DataContext'
import { API_BASE_URL } from '../../../Config/Config'
import BookingLoader from '../../BookingLoader/LoadingAndError'
import BookingDetailSection from '../BookingDetailSection/BookingDetailSection'
import BookingCalculatorSection from '../BookingCalculatorSection/BookingCalculatorSection'
import BookingNavigationBtn from '../BookingNavigationBtn/BookingNavigationBtn'

const ProductOption = () => {
  const { bookingData, setBookingData } = useBooking()
  // const { terminalsData, servicesData } = useLoaderData()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    outboundTerminalError: '',
    inboundTerminalError: '',
  })

  const [inboundTerminals, setInboundTerminals] = useState([])
  const [outboundTerminals, setOutboundTerminals] = useState([])
  const [carCleanService, setCarCleanService] = useState([])
  const [electricChargeService, setElectricChargeService] = useState([])
  console.log(bookingData.totalDaysCost, 'cost for days ----product option')
  console.log(bookingData.totalCostAll, 'total cost all -----product option')

  const navigate = useNavigate()

  useEffect(() => {
    const fetchTerminals = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Terminal/GetAllbyAirPort/${bookingData.airportId}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        const inbounds = data.filter(
          (terminal) => terminal.terminalType === 'InBound'
        )
        const outbounds = data.filter(
          (terminal) => terminal.terminalType === 'OutBound'
        )
        setInboundTerminals(inbounds)
        setOutboundTerminals(outbounds)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchTerminals()
  }, [bookingData.airportId, setBookingData])

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Services/ServiceByAirport/${bookingData.airportId}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        const carClean = data.filter(
          (service) => service.serviceType === 'Car Clean'
        )
        const electricCharge = data.filter(
          (service) => service.serviceType === 'electricCharge'
        )
        setCarCleanService(carClean)
        setElectricChargeService(electricCharge)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }
    fetchServices()
  }, [bookingData.airportId, setBookingData])

  const handleOutBoundTerminalChange = (e) => {
    const selectedId = e.target.value
    const selectedOutBoundTerminal = outboundTerminals.find(
      (outboundTerminal) => outboundTerminal.terminalID === parseInt(selectedId)
    )
    const costDifference =
      selectedOutBoundTerminal.terminalCost -
      (bookingData.outBoundTerminalCost || 0)

    setBookingData((prevData) => ({
      ...prevData,
      outBoundTerminalName: selectedOutBoundTerminal?.terminalName || '',
      outBoundTerminalId: selectedOutBoundTerminal?.terminalID || '',
      outBoundTerminalCost: selectedOutBoundTerminal?.terminalCost || 0,
      totalCostAll: prevData.totalCostAll + costDifference,
    }))
    setErrors((prevErrors) => {
      const { outboundTerminalError, ...restErrors } = prevErrors
      return restErrors
    })
  }

  const handleInBoundTerminalChange = (e) => {
    const selectedId = e.target.value
    const selectedInBoundTerminal = inboundTerminals.find(
      (inboundTerminal) => inboundTerminal.terminalID === parseInt(selectedId)
    )
    const costDifference =
      selectedInBoundTerminal.terminalCost -
      (bookingData.inBoundTerminalCost || 0)
    setBookingData((prevData) => ({
      ...prevData,
      inBoundTerminalName: selectedInBoundTerminal?.terminalName || '',
      inBoundTerminalId: selectedInBoundTerminal?.terminalID || '',
      inBoundTerminalCost: selectedInBoundTerminal?.terminalCost || 0,
      totalCostAll: prevData.totalCostAll + costDifference,
    }))
    setErrors((prevErrors) => {
      const { inboundTerminalError, ...restErrors } = prevErrors
      return restErrors
    })
  }

  const handleCarCleanService = (e) => {
    const selectedId = e.target.value
    const selectedCarCleanService = carCleanService.find(
      (service) => service.serviceID === parseInt(selectedId)
    )
    if (selectedCarCleanService) {
      const costDifference =
        selectedCarCleanService.servicePrice - (bookingData.carCleanCost || 0)

      setBookingData((prevData) => ({
        ...prevData,
        carCleanName: selectedCarCleanService.serviceName,
        carCleanId: selectedCarCleanService.serviceID,
        carCleanCost: selectedCarCleanService.servicePrice,
        totalCostAll: prevData.totalCostAll + costDifference,
      }))
    }
  }

  const handleElectricChargeService = (e) => {
    const selectedId = e.target.value
    const selectedElectricChargeService = electricChargeService.find(
      (service) => service.serviceID === parseInt(selectedId)
    )

    if (selectedElectricChargeService) {
      const costDifference =
        selectedElectricChargeService.servicePrice -
        (bookingData.electricChargeCost || 0)

      setBookingData((prevData) => ({
        ...prevData,
        electricChargeName: selectedElectricChargeService.serviceName,
        electricChargeId: selectedElectricChargeService.serviceID,
        electricChargeCost: selectedElectricChargeService.servicePrice,
        totalCostAll: prevData.totalCostAll + costDifference,
      }))
    }
  }
  const validateForm = () => {
    const newErrors = {}

    if (!bookingData.outBoundTerminalId) {
      newErrors.outboundTerminalError = 'Select Outbound terminal'
    }
    if (!bookingData.inBoundTerminalId) {
      newErrors.inboundTerminalError = 'Select inbound terminal'
    }

    setErrors(newErrors)

    return Object.keys(newErrors)?.length === 0
  }

  async function handleNext() {
    const myobj = {
      email: 'something@gmail.com',
      quoteCode: bookingData.quoteCode,
      StartDateTime: bookingData.parkingStart,
      EndDateTime: bookingData.parkingEnd,
      discountCode: bookingData.discountCode,
      productName: 'Parking',
      productDescription: 'Askaii Parking',
      productPrice: bookingData.totalDaysCost,
      totalCost: bookingData.totalCostAll,
      outboundTerminalID: bookingData.outBoundTerminalId,
      inboundTerminalID: bookingData.inBoundTerminalId,
      selectedCarCleanID: bookingData.carCleanId || null,
      selectedEChargeID: bookingData.electricChargeId || null,
    }
    console.log(myobj)
    if (validateForm()) {
      setLoading(true)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/ProductOption`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: 'something@gmail.com',
              quoteCode: bookingData.quoteCode,
              StartDateTime: bookingData.parkingStart,
              EndDateTime: bookingData.parkingEnd,
              discountCode: bookingData.discountCode,
              productName: 'Parking',
              productDescription: 'Askaii Parking',
              productPrice: bookingData.totalDaysCost,
              totalCost: bookingData.totalCostAll,
              outboundTerminalID: bookingData.outBoundTerminalId,
              inboundTerminalID: bookingData.inBoundTerminalId,
              selectedCarCleanID: bookingData.carCleanId || null,
              selectedEChargeID: bookingData.electricChargeId || null,
            }),
          }
        )
        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        navigate('/booking_detail')
        setLoading(false)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  function handleBack() {
    navigate('/')
  }
  return (
    <>
      {loading && <BookingLoader />}

      <div className="main-container-product">
        <div className="main-product-option">
          <BookingNavbar />
          <div className="product-option-container">
            <BookingDetailSection />

            {/* Product and Calculator Section */}
            <div className="product-options-section">
              <div className="product-options-heading">Product Option</div>

              {/* Outbound Terminal */}
              <div className="product-option">
                <label className="product-option-label">
                  Outbound Terminal: <span className="booking-required">*</span>
                </label>
                <select
                  className="product-option-select"
                  name="outBoundTerminal"
                  onChange={handleOutBoundTerminalChange}
                  value={bookingData.outBoundTerminalId}
                >
                  <option value="" disabled selected>
                    Outbound Terminal
                  </option>
                  {outboundTerminals?.map((terminal, index) => (
                    <option key={index} value={terminal.terminalID}>
                      {terminal.terminalName}{' '}
                      {terminal.terminalCost && '£' + terminal.terminalCost}
                    </option>
                  ))}
                </select>
                {errors.outboundTerminalError && (
                  <p className="booking-required">
                    {errors.outboundTerminalError}
                  </p>
                )}
              </div>

              {/* Inbound Terminal */}
              <div className="product-option">
                <label className="product-option-label">
                  Inbound Terminal: <span className="booking-required">*</span>
                </label>
                <select
                  className="product-option-select"
                  name="inBoundTerminal"
                  onChange={handleInBoundTerminalChange}
                  value={bookingData.inBoundTerminalId}
                >
                  <option value="" disabled selected>
                    Inbound Terminal
                  </option>
                  {inboundTerminals?.map((terminal, index) => (
                    <option key={index} value={terminal.terminalID}>
                      {terminal.terminalName}{' '}
                      {terminal.terminalCost && '£' + terminal.terminalCost}
                    </option>
                  ))}
                </select>
                {errors.inboundTerminalError && (
                  <p className="booking-required">
                    {errors.inboundTerminalError}
                  </p>
                )}
              </div>

              {/* Car Clean */}
              {carCleanService.length > 0 ? (
                <div className="product-option">
                  <label className="product-option-label">Car Clean:</label>
                  <select
                    className="product-option-select"
                    name="carClean"
                    onChange={handleCarCleanService}
                    value={bookingData.carCleanId}
                  >
                    <option value="" disabled selected>
                      Select Car Clean Service (optional)
                    </option>
                    {carCleanService?.map((service) => (
                      <option key={service.serviceID} value={service.serviceID}>
                        {service.serviceName} - {service.serviceDescription}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ''
              )}

              {/* Electric Charge */}
              {electricChargeService.length > 0 ? (
                <div className="product-option">
                  <label className="product-option-label">
                    Electric Charge:
                  </label>

                  <select
                    className="product-option-select"
                    name="electricCharge"
                    onChange={handleElectricChargeService}
                    value={bookingData.electricChargeId}
                  >
                    <option value="" disabled selected>
                      Select Electric Charge Service (optional)
                    </option>
                    {electricChargeService?.map((service) => (
                      <option key={service.serviceID} value={service.serviceID}>
                        {service.serviceName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ''
              )}

              {/* Navigation Buttons */}
            </div>
            <BookingCalculatorSection />
          </div>
          <BookingNavigationBtn
            handleBack={handleBack}
            handleNext={handleNext}
          />
        </div>
      </div>
    </>
  )
}

export default ProductOption
