import React, { useState, useEffect } from 'react'
import Icon from './../../assets/icon_1.png'

import './home.css'

export default function HeroSection() {
  const handleSignUpClick = () => {
    // window.open("/signup");
    // navigate('/signup')
    return 1
  }

  return (
    <div className="container-fluid hero-bg-img" id="home">
      <div className="row home-row-height">
        <div className="col-lg-5 col-md-6 col-sm-12 text-center text-md-left left-section-hero text-box-hero-content">
          <img src={Icon} alt="Icon.png" className="bar-icon" />
          <h1 className="heading-placement">
            Welcome to
            <span className="span-color"> ASKAII </span> ||
            <br />
            Your Ultimate Car Parking Brand
          </h1>
          <p className="para-placement">
            Say goodbye to the stress of finding parking. Whether you’re heading
            to work, going for a short trip, or attending an event--ASKAII
            ensures you find and reserve the perfect spot quickly and easily.
          </p>
          <button
            className="btn btn-outline-success join-btn"
            type="button"
            onClick={handleSignUpClick}
          >
            Sign Up Now
          </button>
        </div>
        <div className="col-lg-7 col-md-6 col-sm-12 ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9 col-md-9 col-sm-9 form-bg-img"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
