import { useBooking } from '../../BookingContext/DataContext'
import './BookingCalculatorSection.css'

const BookingCalculatorSection = () => {
  const { bookingData } = useBooking()
  return (
    <div className="calculator-section">
      <div className="calculator-heading">Total Charges</div>
      <div className="calculator-container">
        <div className="calculator_row">
          <p>LHR Flexible</p>
          <span>£{bookingData.totalDaysCost}</span>
        </div>
        <div className="calculator_row">
          <p>
            {bookingData.outBoundTerminalName &&
            bookingData.outBoundTerminalCost
              ? `Outbound Terminal: ${bookingData.outBoundTerminalName}`
              : ''}
          </p>
          <span>
            {bookingData.outBoundTerminalName &&
            bookingData.outBoundTerminalCost
              ? `£${bookingData.outBoundTerminalCost}`
              : ''}
          </span>
        </div>
        <div className="calculator_row">
          <p>
            {bookingData.inBoundTerminalName && bookingData.inBoundTerminalCost
              ? `Inbound Terminal: ${bookingData.inBoundTerminalName}`
              : ''}
          </p>
          <span>
            {bookingData.inBoundTerminalName && bookingData.inBoundTerminalCost
              ? `£${bookingData.inBoundTerminalCost}`
              : ''}
          </span>
        </div>
        <div className="calculator_row">
          <p>
            {bookingData.carCleanName && bookingData.carCleanCost
              ? bookingData.carCleanName
              : ''}
          </p>
          <span>
            {bookingData.carCleanName && bookingData.carCleanCost
              ? `£${bookingData.carCleanCost}`
              : ''}
          </span>
        </div>
        <div className="calculator_row">
          <p>
            {bookingData.electricChargeName && bookingData.electricChargeCost
              ? bookingData.electricChargeName
              : ''}
          </p>
          <span>
            {bookingData.electricChargeName && bookingData.electricChargeCost
              ? `£${bookingData.electricChargeCost}`
              : ''}
          </span>
        </div>
        <div className="calculator_row">
          <span></span>
          <span className="total_row">£{bookingData.totalCostAll}</span>
        </div>
      </div>
    </div>
  )
}

export default BookingCalculatorSection
