// Booking-navbar.js
import React from 'react'
// import './Booking-navbar.css'
import './BookingNavbar.css'

function BookingNavbar() {
  return (
    <nav className="booking-navbar">
      <ul className="booking-navbar-list">
        <li className="booking-navbar-item">Product Options</li>
        <li className="booking-navbar-item">Booking Details</li>
        <li className="booking-navbar-item">Payment Methods</li>
        <li className="booking-navbar-item">Execute Payment</li>
      </ul>
    </nav>
  )
}

export default BookingNavbar
