// ProtectedBookings.js
import { Navigate } from 'react-router-dom'
import { useBooking } from './DataContext'

const ProtectedBookings = ({ children, requiredData, redirectTo }) => {
  const { bookingData } = useBooking()

  const requiredDataArray = Array.isArray(requiredData)
    ? requiredData
    : [requiredData]
  const isDataComplete = requiredDataArray.every((key) => bookingData[key])

  if (!isDataComplete) {
    return <Navigate to={redirectTo} />
  }

  return children
}

export default ProtectedBookings
