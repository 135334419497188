import React, { useEffect } from "react";
import "./Terms.css";
import { NavLink } from "react-router-dom";

export default function Terms() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="termscontainer">
      <NavLink to={"/"} className="terms-go-back">
        {" "}
        <span> &larr;</span> Go Back
      </NavLink>
      <h1 className="terms-heading"> Terms and Conditions</h1>
      <p className="terms-paragraph">
        Welcome to Askaii Parking! These terms and conditions outline the rules
        and regulations for the use of our website, located at [Your Website
        URL].
      </p>
      <p className="terms-paragraph">
        By accessing this website, we assume you accept these terms and
        conditions. Do not continue to use Askaii Parking if you do not agree to
        take all of the terms and conditions stated on this page.
      </p>
      <h2 className="terms-subheading">Terminology</h2>
      <p className="terms-paragraph">
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements:
      </p>
      <ul className="terms-list">
        <li className="terms-list-item">
          "Client", "You" and "Your" refers to you, the person log on this
          website and compliant to the Company’s terms and conditions.
        </li>
        <li className="terms-list-item">
          "The Company", "Ourselves", "We", "Our" and "Us", refers to our
          Company.
        </li>
        <li className="terms-list-item">
          "Party", "Parties", or "Us", refers to both the Client and ourselves.
        </li>
      </ul>
      <p className="terms-paragraph">
        All terms refer to the offer, acceptance, and consideration of payment
        necessary to undertake the process of our assistance to the Client in
        the most appropriate manner for the express purpose of meeting the
        Client’s needs in respect of provision of the Company’s stated services,
        in accordance with and subject to, prevailing law of the United States.
        Any use of the above terminology or other words in the singular, plural,
        capitalization and/or he/she or they, are taken as interchangeable and
        therefore as referring to same.
      </p>
      <h2 className="terms-subheading">Cookies</h2>
      <p className="terms-paragraph">
        We employ the use of cookies. By accessing Askaii Parking, you agreed to
        use cookies in agreement with the Askaii Parking's Privacy Policy.
      </p>
      <p className="terms-paragraph">
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>
      <h2 className="terms-subheading">License</h2>
      <p className="terms-paragraph">
        Unless otherwise stated, Askaii Parking and/or its licensors own the
        intellectual property rights for all material on Askaii Parking. All
        intellectual property rights are reserved. You may access this from
        Askaii Parking for your own personal use subjected to restrictions set
        in these terms and conditions.
      </p>
    </div>
  );
}
