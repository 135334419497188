import React, { useEffect, useState } from 'react'
import { API_BASE_URL } from '../../../Config/Config'
import { useBooking } from '../../BookingContext/DataContext'
import { useNavigate } from 'react-router-dom'
import BookingLoader from '../../BookingLoader/LoadingAndError'
import BookingNavbar from './../BookingNavbar/BookingNavbar'
import BookingDetailSection from '../BookingDetailSection/BookingDetailSection'
import './BookingDetail.css'
import BookingCalculatorSection from '../BookingCalculatorSection/BookingCalculatorSection'
import BookingNavigationBtn from '../BookingNavigationBtn/BookingNavigationBtn'

const BookingDetail = () => {
  const { bookingData, setBookingData } = useBooking()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  const [allMake, setAllMake] = useState([])
  const [allModel, setAllModel] = useState([])
  const [allColour, setAllColour] = useState([])

  useEffect(() => {
    const allVehicles = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Vehicle/allVehicles`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'true',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setAllMake(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    allVehicles()
  }, [])

  useEffect(() => {
    const GetAllColors = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Vehicle/GetAllColors`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'true',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setAllColour(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    GetAllColors()
  }, [])

  const handleMakeChange = (e) => {
    const selectedId = e.target.value
    if (!(selectedId == 0)) {
      const selecteMake = allMake.find(
        (make) => make.vehicleMakeID === parseInt(selectedId)
      )
      setAllModel(selecteMake?.vehicleModels)

      setBookingData((prevData) => ({
        ...prevData,
        carMakeName: selecteMake?.vehicleMakeName || '',
        carMakeId: selecteMake?.vehicleMakeID || 0,
      }))
      setErrors((prevErrors) => {
        const { carOtherMakeNameError, ...restErrors } = prevErrors
        return restErrors
      })
    }
    if (selectedId == 0) {
      setBookingData((prevData) => ({
        ...prevData,
        carMakeName: 'Other',
        carMakeId: 0,
      }))
    }
  }

  const handleModelChange = (e) => {
    const selectedId = e.target.value
    const selectedModel = allModel?.find(
      (make) => make.vehicleModelID === parseInt(selectedId)
    )
    setBookingData((prevData) => ({
      ...prevData,
      carModelName: selectedModel?.vehicleModelName || '',
      carModelId: selectedModel?.vehicleModelID || 0,
    }))
  }

  const handleColourChange = (e) => {
    const selectedId = e.target.value
    const selectedColour = allColour?.find(
      (colour) => colour.vehicleColorID === parseInt(selectedId)
    )
    setBookingData((prevData) => ({
      ...prevData,
      carColourName: selectedColour?.vehicleColorName || '',
      carColourId: selectedColour?.vehicleColorID || 0,
    }))
  }

  const handleBack = () => {
    navigate('/product_option')
  }

  const handleRegistrationChange = (e) => {
    const { name, type, value, checked } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]:
        type === 'checkbox'
          ? checked
          : value === 'true'
          ? true
          : value === 'false'
          ? false
          : value,
    }))

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }

      const errorFieldName = `${name}Error`

      // Remove error for the changed field if it exists
      if (newErrors[errorFieldName]) {
        delete newErrors[errorFieldName]
      }

      // Additional logic to handle specific toggles
      if (name === 'carMake' && value !== 'Other') {
        // If carMake is not "Other", remove the error for carOtherMakeName
        delete newErrors.carOtherMakeNameError
      }
      if (name === 'differentFromAirport' && !checked) {
        // If differentFromAirport is unchecked, remove the error for flyingFrom
        delete newErrors.flyingFromError
      }
      console.log(newErrors)
      return newErrors
    })
  }
  const validateForm = () => {
    const newErrors = {}

    if (!bookingData.email) {
      newErrors.emailError = 'Please enter your Email'
    }
    if (!bookingData.userSurName) {
      newErrors.userSurNameError = 'Please enter your surname'
    }
    if (!bookingData.carRegistrationNo) {
      newErrors.carRegistrationNoError =
        'Please enter your car registration NO.'
    }
    if (!bookingData.carMakeId && !bookingData.carOtherMakeName) {
      newErrors.carOtherMakeNameError = 'Please select your car make'
    } else if (bookingData.carMakeId === '0' && !bookingData.carOtherMakeName) {
      newErrors.carOtherMakeNameError = 'Please enter your car make'
    }

    if (!bookingData.flyingTo) {
      newErrors.flyingToError = 'Please enter your flying to details'
    }
    if (bookingData.isDifferentFromAirport && !bookingData.flyingFrom) {
      newErrors.flyingFromError = 'Please enter your flying from details'
    } else if ('') {
      return
    }
    if (!bookingData.inBoundFlightNo) {
      newErrors.inBoundFlightNoError = 'Please enter your inbound flight number'
    }
    if (!bookingData.isAgreeOnTerms) {
      newErrors.isAgreeOnTermsError = 'Please select agree on terms'
    }
    setErrors(newErrors)

    return Object.keys(newErrors)?.length === 0
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      setLoading(true)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/BookingDetail`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              // customerFirstName: bookingData.userFirstName,
              // quoteCode: bookingData.qouteCode,
              // customerLastName: bookingData.userSurName,
              // customerEmail: bookingData.email,
              // phoneNumber: bookingData.userMobileNo,
              // customerTitle: bookingData.userTitle,
              // customerDescription: null,
              // companyName: bookingData.userCompanyName,
              // vehicleName: null,
              // registrationNumber: bookingData.carRegistrationNo,
              // vehicleVersion: null,
              // vehicleMakeID: bookingData.carMakeId,
              // vehcileModelID: bookingData.carModelId,
              // vehicleColorID: bookingData.carColourId,
              // otherMake: bookingData.carOtherMakeName,
              // otherModel: bookingData.carOtherModelName,
              // otherColor: bookingData.carOtherColourName,
              // flyingTo: bookingData.flyingTo,
              // flyingFrom: bookingData.flyingFrom,
              // inboundFlightNo: bookingData.inBoundFlightNo,
              // outboundFlightNo: bookingData.outBoundFlightNo,
              // isRegister: bookingData.userIsRegister,
              // isPromotions: bookingData.promotions,
              // isFlyingfromDifferent: bookingData.isDifferentFromAirport,
              customerFirstName: bookingData.userFirstName,
              quoteCode: bookingData.quoteCode,
              customerLastName: bookingData.userSurName,
              customerEmail: bookingData.email,
              phoneNumber: bookingData.userMobileNo,
              customerTitle: bookingData.userTitle,
              customerDescription: null,
              companyName: bookingData.userCompanyName,
              vehicleName: null,
              registrationNumber: bookingData.carRegistrationNo,
              vehicleVersion: null,
              vehicleMakeID: bookingData.carMakeId,
              vehcileModelID: bookingData.carModelId,
              vehicleColorID: bookingData.carColourId,
              otherMake: bookingData.carOtherMakeName,
              otherModel: bookingData.carOtherModelName,
              otherColor: bookingData.carColourName,
              flyingTo: bookingData.flyingTo,
              flyingFrom: bookingData.flyingFrom,
              inboundFlightNo: bookingData.inBoundFlightNo,
              outboundFlightNo: bookingData.outBoundFlightNo,
              isRegister: true,
              isPromotions: bookingData.promotions,
              isFlyingfromDifferent: bookingData.isDifferentFromAirport,
            }),
          }
        )
        if (!response.ok) {
          console.log(bookingData)
          throw new Error('Failed to fetch quote')
        }
        setLoading(false)

        navigate('/payment_method')
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }
  return (
    <>
      {loading && <BookingLoader />}

      <div className="main-container-detail">
        <div className="main-detail-option">
          <BookingNavbar />
          <div className="detail-option-container">
            {/* <BookingDetailSection /> */}
            <BookingDetailSection />
            {/* Detail and Calculator Section */}
            <div className="detail-options-section">
              <div className="detail-options-heading">Personal Details</div>

              <div className="detail-option">
                <label className="detail-option-label">Promotion:</label>
                <div>
                  <input
                    type="radio"
                    name="promotions"
                    value="false"
                    onChange={handleRegistrationChange}
                    checked={bookingData.promotions === false}
                  />{' '}
                  No
                </div>
                <div>
                  <input
                    type="radio"
                    name="promotions"
                    value="true"
                    onChange={handleRegistrationChange}
                    checked={bookingData.promotions === true}
                  />{' '}
                  Yes
                </div>
              </div>

              <div className="detail-option">
                <label className="detail-option-label">Title:</label>
                <select
                  className="detail-option-select"
                  name="userTitle"
                  onChange={handleRegistrationChange}
                  value={bookingData.userTitle}
                >
                  <option value="" disabled>
                    Select an Option
                  </option>
                  <option value="Other">Other</option>
                  <option value="Mr. ">Mr</option>
                  <option value="Mrs. ">Mrs</option>
                  <option value="Ms. ">Ms</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Email:
                  <span className="booking-required"> *</span>
                </label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="email"
                  value={bookingData.email}
                  onChange={handleRegistrationChange}
                />
                {errors.emailError && (
                  <p className="booking-required">{errors.emailError}</p>
                )}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">First Name:</label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="userFirstName"
                  value={bookingData.userFirstName}
                  onChange={handleRegistrationChange}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  SurName:
                  <span className="booking-required"> * </span>
                </label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="userSurName"
                  value={bookingData.userSurName}
                  onChange={handleRegistrationChange}
                  required
                />
                {errors.userSurNameError && (
                  <p className="booking-required">{errors.userSurNameError}</p>
                )}
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Company Name:</label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="userCompanyName"
                  value={bookingData.userCompanyName}
                  onChange={handleRegistrationChange}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Phone No:</label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="userMobileNo"
                  value={bookingData.userMobileNo}
                  onChange={handleRegistrationChange}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Source:</label>
                <select
                  className="detail-option-select"
                  name="userSource"
                  onChange={handleRegistrationChange}
                  value={bookingData.userSource}
                >
                  <option selected disabled value={''}>
                    where did you hear about us?
                  </option>
                  <option value="website">Website</option>
                  <option value="socialMedia">Social Media</option>
                  <option value="friendsAndFamily">Friend & Family</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {bookingData.userSource === 'other' ? (
                <div className="detail-option">
                  <label className="detail-option-label">Other Source:</label>
                  <input
                    className="detail-option-select"
                    type="text"
                    name="otherUserSource"
                    onChange={handleRegistrationChange}
                    value={bookingData.otherUserSource}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="detail-options-section">
              <div className="detail-options-heading">Vehicle Details</div>

              <div className="detail-option">
                <label className="detail-option-label">
                  Registration No: <span className="booking-required">*</span>
                </label>

                <input
                  className="detail-option-select"
                  type="text"
                  name="carRegistrationNo"
                  value={bookingData.carRegistrationNo}
                  onChange={handleRegistrationChange}
                />
                {errors.carRegistrationNoError && (
                  <p className="booking-required">
                    {errors.carRegistrationNoError}
                  </p>
                )}
                <div>e.g. AB51ABC</div>
                <div>
                  Your registration is used to recognise your car on entry to
                  the car park.
                </div>
              </div>

              <div className="detail-option">
                <label className="detail-option-label">Car Make:</label>
                <select
                  className="detail-option-select"
                  onChange={handleMakeChange}
                  value={bookingData.carMakeId || ''}
                >
                  <option value="0">Other</option>
                  {allMake.map((make) => (
                    <>
                      <option
                        key={make.vehicleMakeID}
                        value={make.vehicleMakeID}
                      >
                        {make.vehicleMakeName}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              {bookingData.carMakeId ? (
                ''
              ) : (
                <div className="detail-option">
                  <label className="detail-option-label">
                    Other Car Make: <span className="booking-required">*</span>
                  </label>
                  <input
                    className="detail-option-select"
                    type="text"
                    name="carOtherMakeName"
                    onChange={handleRegistrationChange}
                    value={bookingData.carOtherMakeName}
                  />
                  {errors.carOtherMakeNameError && (
                    <p className="booking-required">
                      {errors.carOtherMakeNameError}
                    </p>
                  )}
                </div>
              )}
              <div className="detail-option">
                <label className="detail-option-label">Car Model:</label>
                <select
                  className="detail-option-select"
                  onChange={handleModelChange}
                  value={bookingData.carModelId || ''}
                >
                  <option value="">Other</option>
                  {allModel?.map((model) => (
                    <option
                      key={model.vehicleModelID}
                      value={model.vehicleModelID}
                    >
                      {model?.vehicleModelName}
                    </option>
                  ))}
                </select>
              </div>
              {bookingData.carModelId ? (
                ''
              ) : (
                <div className="detail-option">
                  <label className="detail-option-label">
                    Other Car Model:
                  </label>
                  <input
                    className="detail-option-select"
                    type="text"
                    name="carOtherModelName"
                    onChange={handleRegistrationChange}
                    value={bookingData.carOtherModelName}
                  />
                </div>
              )}

              <div className="detail-option">
                <label className="detail-option-label">Car Color:</label>
                <select
                  className="detail-option-select"
                  onChange={handleColourChange}
                  value={bookingData.carColourId || ''}
                >
                  <option>Other</option>
                  {allColour?.map((colour) => (
                    <option
                      key={colour.vehicleColorID}
                      value={colour.vehicleColorID}
                    >
                      {colour?.vehicleColorName}
                    </option>
                  ))}
                </select>
              </div>
              {bookingData.carColourId ? (
                ''
              ) : (
                <div className="detail-option">
                  <label className="detail-option-label">
                    Other Car Color:
                  </label>
                  <input
                    className="detail-option-select"
                    type="text"
                    name="carOtherColourName"
                    onChange={handleRegistrationChange}
                    value={bookingData.carOtherColourName}
                  />
                </div>
              )}
            </div>{' '}
            <div className="detail-options-section">
              <div className="detail-options-heading">Flight Details</div>

              <div className="detail-option">
                <label className="detail-option-label">
                  Flying To:
                  <span className="booking-required"> * </span>
                </label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="flyingTo"
                  onChange={handleRegistrationChange}
                  value={bookingData.flyingTo}
                />
                {errors.flyingToError && (
                  <p className="booking-required">{errors.flyingToError}</p>
                )}
              </div>

              <div className="detail-option">
                <label className="detail-option-label ">
                  Different From Airport:
                  <input
                    className="mx-1"
                    type="checkbox"
                    name="isDifferentFromAirport"
                    onChange={handleRegistrationChange}
                    value={bookingData.isDifferentFromAirport}
                    checked={bookingData.isDifferentFromAirport === true}
                  />
                </label>
              </div>

              {bookingData.isDifferentFromAirport === true ? (
                <div className="detail-option">
                  <label className="detail-option-label">
                    Flying From: <span className="booking-required"> *</span>
                  </label>
                  <input
                    className="detail-option-select"
                    type="text"
                    name="flyingFrom"
                    onChange={handleRegistrationChange}
                    value={bookingData.flyingFrom}
                  />
                  {errors.flyingFromError && (
                    <p className="booking-required">{errors.flyingFromError}</p>
                  )}
                </div>
              ) : (
                ''
              )}

              <div className="detail-option">
                <label className="detail-option-label">
                  Outbound Flight No:
                </label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="outBoundFlightNo"
                  onChange={handleRegistrationChange}
                  value={bookingData.outBoundFlightNo}
                />
                <div>e.g. ABC123</div>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Inbound Flight No:{' '}
                  <span className="booking-required"> *</span>
                </label>
                <input
                  className="detail-option-select"
                  type="text"
                  name="inBoundFlightNo"
                  onChange={handleRegistrationChange}
                  value={bookingData.inBoundFlightNo}
                />
                {errors.inBoundFlightNoError && (
                  <p className="booking-required">
                    {errors.inBoundFlightNoError}
                  </p>
                )}
              </div>
              <div className="detail-option">
                <label className="detail-option-label ">
                  Do you agree on terms?
                  <input
                    className="mx-1"
                    type="checkbox"
                    name="isAgreeOnTerms"
                    onChange={handleRegistrationChange}
                    value={bookingData.isAgreeOnTerms}
                    checked={bookingData.isAgreeOnTerms === true}
                  />
                </label>
                {errors.isAgreeOnTermsError && (
                  <p className="booking-required">
                    {errors.isAgreeOnTermsError}
                  </p>
                )}
              </div>
            </div>
            {/* <BookingCalculatorSection /> */}
            <BookingCalculatorSection />
          </div>
          <BookingNavigationBtn
            handleBack={handleBack}
            handleNext={handleNext}
          />
        </div>
      </div>
    </>
  )
}

export default BookingDetail
