import React, { useEffect } from "react";
import "../Privacy/Privacy.css";
import { NavLink } from "react-router-dom";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="container h-100">
      <NavLink to={"/"} className="terms-go-back">
        {" "}
        <span> &larr;</span> Go Back
      </NavLink>
      <h3 className="privacy-heading">Privacy Policy</h3>

      <h4 className="privacy-subheading">Our Commitment to Privacy</h4>
      <p className="privacy-paragraph">
        <strong>Askaii Parking</strong> is committed to protecting the privacy
        and security of your personal information. This Privacy Policy outlines
        the types of information we collect from you, how we use and protect
        that information, and your rights regarding your personal data. By using
        our website and services, you consent to the practices described in this
        policy.
      </p>

      <h4 className="privacy-subheading">What information do we collect?</h4>
      <p className="privacy-paragraph">
        We collect information from you when you subscribe to our newsletter or
        marketing updates, fill out a form, or submit property information.
      </p>
      <p className="privacy-paragraph">
        When ordering or registering on our site, you may be asked to enter your
        name, email address, mailing address, or phone number. We transfer
        information about you if Askaii Parking is acquired by or merged with
        another company. In this event, Askaii Parking will notify you before
        the information is transferred and becomes subject to a different
        privacy policy.
      </p>

      <p className="privacy-paragraph">
        Any of the information we collect from you may be used in one of the
        following ways:
      </p>
      <ul className="privacy-list">
        <li className="privacy-list-item">
          To process transactions: Your information will be used in the
          completion of buying processes, property assessments, and property
          documentation.
        </li>
        <li className="privacy-list-item">
          To communicate with you: The contact information (phone number and
          email) you provide may be used to send you information, respond to
          inquiries, and/or other requests or questions.
        </li>
      </ul>

      <h6 className="privacy-subheading">Data Security</h6>
      <p className="privacy-paragraph">
        We take reasonable measures to protect your personal information from
        unauthorized access, alteration, disclosure, or destruction. However, no
        method of transmission over the Internet or electronic storage is
        entirely secure, and we cannot guarantee absolute security.
      </p>

      <h6 className="privacy-subheading">
        Children's Online Privacy Protection Act Compliance
      </h6>
      <p className="privacy-paragraph">
        We are in compliance with the requirements of COPPA (Children's Online
        Privacy Protection Act); we do not collect any information from anyone
        under 13 years of age. Our website, products, and services are all
        directed to people who are at least 13 years old or older.
      </p>

      <h6 className="privacy-subheading">
        Opt-In Information and Third-Party Sharing
      </h6>
      <p className="privacy-paragraph">
        We respect your privacy choices, especially concerning the sharing of
        your personal information. Any information you provide through opt-in
        consent will not be shared with third parties for marketing or
        promotional purposes.
      </p>

      <h6 className="privacy-subheading">Opt-In for Marketing Text Messages</h6>
      <p className="privacy-paragraph">
        By submitting the contact form and signing up for texts, you consent to
        receive marketing text messages from Askaii Parking at the number
        +404-579-8662. Consent is not a condition of purchase. Message and data
        rates may apply. You can unsubscribe at any time by replying STOP to the
        received SMS (texts) or clicking the unsubscribe link (where available)
        in the marketing text messages.
      </p>

      <h6 className="privacy-subheading">Your Rights</h6>
      <p className="privacy-paragraph">
        You have the right to access, update, correct, and delete your personal
        information we hold. If you wish to exercise any of these rights, please
        contact us at Sheena.murry@sevenpinesinvest.com or call us on
        +404-579-8662.
      </p>

      <h6 className="privacy-subheading">Changes to this Privacy Policy</h6>
      <p className="privacy-paragraph">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        The updated policy will be posted on our website, and the effective date
        will be revised accordingly.
      </p>

      <h6 className="privacy-subheading">Contacting Us</h6>
      <p>
        If there are any questions regarding this privacy policy, you may
        contact us with the information on our contact page.
      </p>
      <p className="privacy-paragraph">
        {/* <strong>Askaii Parking</strong> */}
      </p>
    </div>
  );
}
