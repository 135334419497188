import React from "react";
import "./AboutUs.css";

const ParkingInfo = () => {
  return (
    <div className="parking-info-container">
      <header className="header">
        <h1>Heathrow Airport Askaii Parking</h1>
      </header>
      <main className="main-content">
        <section className="contact-info">
          <h4>Email</h4>
          <p>contact@askaiparking.co.uk</p>
          <h4>Telephone</h4>
          <p>0208 893 2294</p>
          <p>0208 759 5252</p>
          <h4>Map</h4>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10000.0!2d-0.4542961!3d51.470022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTHCsDI4JzExLjgiTiAwwrAyNycyNi4yIlc!5e0!3m2!1sen!2suk!4v1688191893955!5m2!1sen!2suk"
              width="300"
              height="200"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <footer className="footer">
            <a href="#terms">Site Terms and Conditions</a>
          </footer>
        </section>
        <section className="details">
          <h4>Details</h4>
          <h3>How Meet & Greet Parking Works</h3>
          <p>
            Meet and Greet Parking with Askaii is one of the most convenient and
            hassle-free ways to park your vehicle before you fly away for your
            family holiday or your business trip. Askaii can save you valuable
            time as well as making the whole parking process hassle-free. Let
            the airport parking professionals concentrate on looking after your
            car so that you can enjoy your trip.
          </p>
          <h3>Make a booking:</h3>
          <p>
            Once you have booked with us, you will receive a booking
            confirmation and directions on where to meet us at the relevant
            terminal. Simply put your dates into the quote screen and obtain a
            price, be sure to check for Askaii Discount Codes for Airport
            Parking at Heathrow.
          </p>
          <h3>On your way to the Airport:</h3>
          <p>
            We ask our customers to call us when approximately 30 minutes from
            reaching the terminal, and we can inform you when a uniformed Askaii
            driver will be available.
          </p>
          <h3>Meeting an Askaii Meet and Greet Representative:</h3>
          <p>
            We always meet you at your vehicle. Your driver will assist you with
            your luggage and help you to prepare for the next stage of your
            journey. We feel that this is much more personal than meeting you
            away from your car in the lift lobby.
          </p>
          <p>
            Meeting you at your vehicle allows you the opportunity to check your
            vehicle over with staff and you can take photos of your car while
            our staff member is still present. Also, remember to double-check
            you have everything you need for your trip!
          </p>
          <h3>Why the photos?</h3>
          <p>
            When you meet your Askaii driver, they will carry out a cursory
            check of the vehicle. We always encourage customers to take detailed
            photos of their vehicle from all angles. This is purely for your
            peace of mind, and it is always good practice when leaving your car
            in someone else’s care.
          </p>
          <h3>Askaii parking your vehicle:</h3>
          <p>
            Whilst your car is in our care, we will look after it as if it is
            our own. Our drivers are insured on a Comprehensive basis and will
            drive your vehicle back to our secure car park for safe storage.
            Your vehicle is fully insured for the entire duration; on-site cover
            is included. Our car park has CCTV on entry, 24-hour security, and
            there is no public access.
          </p>
          <h3>When you return:</h3>
          <p>
            After you have collected all of your luggage, call the Duty
            Controller on 0208 759 5252, and we’ll despatch your vehicle. Please
            check your vehicle whilst the Askaii staff are with you; we cannot
            accept liability for any issues reported once your vehicle has been
            removed from our care.
          </p>
          <p>
            Askaii will pay for 15 minutes of parking for your return, and
            additional costs will need to be paid by the customer. When the
            vehicle has been signed back to you, staff will validate your exit
            ticket, and you can make your way home!
          </p>
          <h3>Making things easier for you:</h3>
          <p>
            Why not take advantage of some of our add-on services? Click here
            for more information.
          </p>
          <p>
            Be sure to register with us and opt in for marketing so that we can
            keep you informed of our discounted rates and offers! (see our
            Privacy Policy)
          </p>
          <p>
            See our FAQ’s page for more information on our award-winning Meet
            and Greet service at Heathrow!
          </p>
          <p>Airport Parking, a Much Better Way!</p>
        </section>
      </main>
    </div>
  );
};

export default ParkingInfo;
