import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./ReturnURL.css";
import { API_BASE_URL } from "../../../Config/Config";
import { useBooking } from "../../BookingContext/DataContext";
import Paymentok from "../../../assets/check (1).png";
import Paymentcancel from "./../../../assets/cross.png";
import BookingLoader from "../../BookingLoader/LoadingAndError";

function ReturnURL() {
  const [visibleComponent, setVisibleComponent] = useState("first");
  const { bookingData, setBookingData } = useBooking();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("paymentId");
  const payerId = searchParams.get("PayerID");
  function handleClose() {
    // window.history.replaceState(null, null, "/");

    // window.history.pushState(null, null, "/");

    // window.location.replace("/");
    window.close();
  }

  function handleCancelPaypal() {
    setVisibleComponent("cancel");
  }

  const handleExecutePaypal = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/ExecutePayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentId: paymentId,
            payerId: payerId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch quote");
      }
      const data = await response.json();

      setBookingData((prevData) => ({
        ...prevData,
        paymentState: data.state,
      }));
      setLoading(false);
      setVisibleComponent("success");
    } catch (err) {
      console.error(err.message);
      // Handle the error (e.g., set an error message in the state)
      setLoading(false);
    } finally {
      // Optionally, handle any cleanup or final state updates
      // navigate("/");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <BookingLoader />}

      {visibleComponent === "first" && (
        <div>
          <div className="booking-eight-main-box">
            <div className="booking-eight-inner-box">
              <div className="booking-eight-inner-box-heading">
                <h6>Confirm your payment</h6>
                <p>Quickly and secure, free transactions.</p>
              </div>
              <div className="booking-eight-inner-box-middel">
                <div className="booking-eight-inner-box-middel-data">
                  <h6>Details</h6>
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Date</p>
                    <p>
                      {" "}
                      {new Date().toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>

                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Email</p>
                    <p>{bookingData.email}</p>
                  </div>
                  <div className="booking-eight-inner-box-middel-data-line-line">
                    <hr />
                  </div>
                  {bookingData.toggleDetails ? (
                    <>
                      <div className="calculator-values">
                        <div className="single-value">
                          <span>{bookingData.airportCode} Flexible</span>
                          <span>£{bookingData.totalDaysCost}</span>
                        </div>
                        <div className="single-value">
                          <span>
                            {bookingData.outBoundTerminalName &&
                            bookingData.outBoundTerminalCost
                              ? `Outbound Terminal: ${bookingData.outBoundTerminalName}`
                              : ""}
                          </span>
                          <span>
                            {bookingData.outBoundTerminalName &&
                            bookingData.outBoundTerminalCost
                              ? `£${bookingData.outBoundTerminalCost}`
                              : ""}
                          </span>
                        </div>
                        <div className="single-value">
                          <span>
                            {bookingData.inBoundTerminalName &&
                            bookingData.inBoundTerminalCost
                              ? `Inbound Terminal: ${bookingData.inBoundTerminalName}`
                              : ""}
                          </span>
                          <span>
                            {bookingData.inBoundTerminalName &&
                            bookingData.inBoundTerminalCost
                              ? `£${bookingData.inBoundTerminalCost}`
                              : ""}
                          </span>
                        </div>
                        <div className="single-value">
                          <span>
                            {bookingData.carCleanName &&
                            bookingData.carCleanCost
                              ? bookingData.carCleanName
                              : ""}
                          </span>
                          <span>
                            {bookingData.carCleanName &&
                            bookingData.carCleanCost
                              ? `£${bookingData.carCleanCost}`
                              : ""}
                          </span>
                        </div>
                        <div className="single-value">
                          <span>
                            {bookingData.electricChargeName &&
                            bookingData.electricChargeCost
                              ? bookingData.electricChargeName
                              : ""}
                          </span>
                          <span>
                            {bookingData.electricChargeName &&
                            bookingData.electricChargeCost
                              ? `£${bookingData.electricChargeCost}`
                              : ""}
                          </span>
                        </div>
                        <div className="total-value">
                          <span></span>
                          <span>£{bookingData.totalCostAll}</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="booking-eight-inner-box-middel-data-line">
                    <p>Total amount</p>
                    <p>{bookingData.totalCostAll}</p>
                  </div>
                </div>
              </div>

              <div className="booking-eight-inner-box-bottom">
                <p
                  type="button"
                  onClick={() =>
                    setBookingData((prevData) => ({
                      ...prevData,
                      toggleDetails: !bookingData.toggleDetails,
                    }))
                  }
                >
                  {bookingData.toggleDetails ? "Hide" : "Show"} details
                </p>

                <div className="booking-eight-inner-box-bottom-button">
                  <button onClick={handleCancelPaypal}>Cancel Payment</button>
                  <button onClick={handleExecutePaypal}>Confirm Payment</button>
                  {/* <button onClick={() => setVisibleComponent("success")}>
                    Confirm Payment
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {visibleComponent === "success" && (
        <div>
          <div className="payment-success-container-main">
            <div className="payment-success-container">
              <img src={Paymentok} alt="" />
              <h1>Payment Success</h1>
              <p>
                You just make a transaction to{" "}
                <NavLink to={"/"}>Askaii.com</NavLink> £
                {bookingData.totalCostAll} from your wallet
              </p>
              <div className="actions9">
                <button
                  type="button"
                  onClick={handleClose}
                  className="pay-now-btn9"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {visibleComponent === "cancel" && (
        <div>
          <div className="payment-success-container-main">
            <div className="payment-success-container">
              <img src={Paymentcancel} alt="Description of image" />
              <h1>Payment Cancelled</h1>
              <p>Your transaction has been cancelled successfully!</p>
              <div className="actions9">
                {
                  <button onClick={handleClose} className={"pay-now-btn9"}>
                    Close
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReturnURL;
