// useFetchAndRedirect.js
import { useBooking } from './DataContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { API_BASE_URL } from '../../Config/Config'

function useFetchAndRedirect() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const quoteCode = searchParams.get('quoteCode')
  // const { quoteCode } = useParams('quoteCode')
  const navigate = useNavigate()
  const { setBookingData } = useBooking()
  console.log(quoteCode)

  useEffect(() => {
    const fetchQuoteData = async () => {
      try {
        const response = await fetch(
          // `https://${API_BASE_URL}/api/Booking/GetQuoteSteps?quoteCode=${quoteCode}`
          `https://api.askaii.co.uk/api/Booking/GetQuoteSteps?quoteCode=${quoteCode}`
        )
        if (!response.ok) throw new Error('Failed to fetch quote data')
        // if (!response.ok) ('Failed to fetch quote data')
        const data = await response.json()
        console.log(data)

        const { quote, quoteSteps } = data

        setBookingData((prevData) => ({
          ...prevData,
          // quote.quoteID: 299,
          userFirstName: quote.fullName,
          totalDays: Math.ceil(
            (new Date(quote.endDateTime) - new Date(quote.startDateTime)) /
              (1000 * 60 * 60 * 24)
          ),
          userMobileNo: quote.phoneNumber,
          email: quote.customerEmail,
          quoteCode: quote.quoteCode,
          parkingStart: quote.startDateTime,
          parkingEnd: quote.endDateTime,
          // quote.createdDate: null,
          totalCostAll: quote.totalCost,
          flyingFrom: quote.flyingFrom,
          flyingTo: quote.flyingTo,
          inBoundFlightNo: quote.inboundFlightNo,
          outBoundFlightNo: quote.outboundFlightNo,
          outBoundTerminalId: quote.outboundTerminalID,
          inBoundTerminalId: quote.inboundTerminalID,
          airportName: quote.location,
          userCompanyName: quote.companyName,
          userSource: quote.source,
          carCleanId: quote.selectedCarCleanID,
          electricChargeId: quote.selectedEChargeID,
          discountCode: quote.discountCode,
          // :quote.productName,
          // :quote.productDescription,
          totalDaysCost: quote.productPrice,
          airportId: quote.airportID,
          // :quote.isRegister,
          promotions: quote.isPromotions,
          // :quote.isDeleted,
          // :quote.isActive,
          isDifferentFromAirport: quote.isFlyingfromDifferent,
          // :quote.paymentStatus,
          // map additional fields from quote data here
        }))

        // Redirect based on quoteSteps values
        // if (!quoteSteps.isQuote) navigate('/')
        // else if (!quoteSteps.isProductOption) navigate('/product_option')
        // else if (quoteSteps.isBookingDetails) navigate('/booking_detail')
        // else
        if (quoteSteps.isBookingDetails) navigate('/payment_method')
        else if (quoteSteps.isProductOption) navigate('/booking_detail')
        else if (quoteSteps.isQuote) navigate('product_option')
        // else if (quoteSteps.isPaymentStatus) navigate('/payment_method')
      } catch (error) {
        // alert(bookingData.airportID)
        console.error('Error fetching quote data:', error)
      }
    }

    if (quoteCode) fetchQuoteData()
  }, [quoteCode, navigate, setBookingData])

  return null // Hook doesn't return data, just handles fetching and redirection
}

export default useFetchAndRedirect
