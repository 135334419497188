import React, { useState } from 'react'
import './UserSubscription.css'
import { API_BASE_URL } from '../../Config/Config'

export default function UserSubscription() {
  const [email, setEmail] = useState('')
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMess, setErrorMess] = useState('')

  const link = 'https://askaii.co.uk'
  const text = 'Click Here !'

  const test = (
    <a
      href="https://askaii.co.uk/"
      target="_blank"
      rel="noreferrer"
      value="   ASKAII"
      onChange={(e) => setErrorMess(e.target.value)}
    >
      ASKAII
    </a>
  )

  // console.log(test.props.value)

  const handleSubscribe = async (e) => {
    e.preventDefault()
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newEmail = {
      To: email,
      Subject: 'Subscribed Successfully!',
      Body: `Congrats! You are subscribed with ${test.props.value}.Thank you for subscribing! We will reach out to you with relevant information about our parking services. Stay tuned! To unsubscribe, <a href="https://askaii.co.uk/?name=faizan" target="_blank" rel="noreferrer">Click Here!</a>
      `,

      //             Body: `Congrats! You are subscribed with ${test.props.value}.
      //             Thank you for subscribing! We will reach out to you with relevant information about our parking services. Stay tuned!

      //             To unsubscribe, <a href="https://askaii.co.uk/?name=faizan" target="_blank" rel="noreferrer">Click Here!</a>
      // `,
    }
    const newEmailSub = {
      Email: email,
      Unsubscribe: false,
    }
    if (emailRegex.test(email)) {
      try {
        const responseEmail = await fetch(`${API_BASE_URL}/api/email/Send`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newEmail),
        })

        const responseEmailSubscription = await fetch(
          `${API_BASE_URL}/api/UserSubscription/Add`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newEmailSub),
          }
        )

        // Check if the response is okay before parsing JSON
        if (!responseEmail.ok && !responseEmailSubscription.ok) {
          throw new Error('Failed to subscribe. Please try again.')
        }

        const dataEmailSubscription = await responseEmailSubscription.json()
        const dataEmail = await responseEmail.json()

        console.log(dataEmail)
        console.log(dataEmailSubscription)
        // if (data) {
        // }
      } catch (err) {
        console.log(err.message)
        setIsSubscribed(true)
        setErrorMessage('')
        setEmail('')
        // setErrorMessage('Something went wrong. Please try again.')
      }
    } else {
      setErrorMessage('Please enter a valid email address.')
      setIsSubscribed(false)
    }
  }

  return (
    <div className="user-container-width">
      <h1 className="user-h1">Stay Updated with Our Parking Services!</h1>
      <p className="user-p-tag">
        Enter your email address to stay informed about our latest parking
        services and offers. By subscribing, you will receive exclusive updates
        and promotions directly in your inbox. Don’t miss out on any important
        information!
      </p>
      <div className="email-form">
        <form>
          <div className="form-group">
            <input
              type="email"
              id="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="button" className="sub-btn" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
      {isSubscribed && (
        <p className="user-p-tag">
          Thank you for subscribing! We will reach out to you with relevant
          information about our parking services. Stay tuned!
        </p>
      )}
    </div>
  )
}
